import React from "react";
import styles from "./Home.module.scss";
import homeTopImage from "../img/home_top.png";
import topBackground from "../img/top_background.png";
import bottomBackground from "../img/bottom_background.png";
import dateRangeText from "../img/date_range.png";
import homeTopLine from "../img/home_top_line.png";
import { ReactComponent as PlusIcon } from "../img/plus_orange.svg";
import BonusImg from "../img/bottom_bonus.png";
import { ReactComponent as NotiIcon } from "../img/noti_icon.svg";
import { useCart } from "../context/CartContext";
import { useEffect } from "react";
import { getCookie, setCookie } from "../cookie/cookie";
import { Link, useLocation } from "react-router-dom";
import { pageView, linkClickEvent } from "../api/ga4";
export default function Home() {
  const { createCart } = useCart();
  const location = useLocation();
  useEffect(() => {
    pageView("home", location.pathname);
  }, [location]);
  useEffect(() => {
    const isCookies = getCookie("cartId");
    if (isCookies) {
      return;
    } else {
      createCart().then((res) =>
        setCookie("cartId", res?.cartCreate?.cart?.id, {
          withCredentials: true,
          httpOnly: false,
          path: "/",
          secure: true,
          sameSite: "None",
        })
      );
    }
    //
  }, [location]);
  const onLinkClick = () => {
    linkClickEvent("Home", "Product-Detail", location.pathname);
  };
  return (
    <div className={styles.container}>
      <img src={topBackground} data-content="top" alt="" />
      <img src={bottomBackground} data-content="bottom" alt="" />

      <section className={styles.top}>
        <img src={homeTopImage} alt="" data-content="top_main" />
        <img src={dateRangeText} alt="" data-content="top_date" />
        <p>
          This shirt is a special item created to commemorate all Breeders who
          survived in the Apocalypse server of Dino Mutant. It was made as a
          token of gratitude for all the players who love the game. Wear it to
          show your passion and pride in surviving!
        </p>
        <Link to="/product/detail" onClick={onLinkClick}>
          Buy Now
        </Link>
        <img src={homeTopLine} alt="" data-content="top_line" />
      </section>
      <section className={styles.bottom}>
        <div className={styles.bottom_title}>
          <p>Pre-Order</p>
          <p>I survived beta 1.0 T-Shirt</p>
        </div>
        <div className={styles.img} />

        <PlusIcon />
        <img src={BonusImg} alt="" data-content="bonus" />
        <div className={styles.bonus}>
          <p>Bonus Sticker!</p>
          <p>Each T-shirt comes with one bonus sticker!</p>
        </div>
        <div className={styles.notice}>
          <NotiIcon />
          <p>
            The image above is a mockup, and specifications other than the
            graphic may differ from the actual product. This item is a
            [made-to-order] product, and production may take approximately 3
            weeks starting from the order deadline on December 24. Please note
            that this product is designed slightly smaller than USA Fit
            T-shirts. Refer to the size chart on the detailed product page.
          </p>
        </div>
      </section>
    </div>
  );
}
