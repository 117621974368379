export const order = [
  {
    id: 1,
    title: "I need help with my order.",
    context: (
      <p>
        Please contact us at{" "}
        <a href="mailto:help-shop@mondayoff.me">help-shop@mondayoff.me.</a>
      </p>
    ),
  },
  {
    id: 2,
    title: " I entered the wrong address. Can I change it",
    context: (
      <p>
        If your order hasn’t shipped yet, we can help. Email&nbsp;
        <a href="mailto:help-shop@mondayoff.me">help-shop@mondayoff.me.</a> with
        your order number, name, and the updated address. Note: While we’ll do
        our best, we cannot guarantee changes.
      </p>
    ),
  },
  {
    id: 3,
    title: "I want to cancel my order.",
    context: (
      <p>
        You can cancel directly from the <strong>‘Order Details’</strong> page
        if your order is still in the <strong>[Ordered]</strong> status. If the
        status is <strong>[Preparing shipment]</strong>, the order may already
        have been shipped. In this case, email&nbsp;
        <a href="mailto:help-shop@mondayoff.me">help-shop@mondayoff.me.</a> with
        your order number and name to request cancellation. Orders that have
        already been shipped cannot be canceled.
      </p>
    ),
  },
  {
    id: 4,
    title: "Can I add or remove items from an already placed order?",
    context: (
      <p>Unfortunately, you’ll need to cancel the order and place a new one.</p>
    ),
  },
];

export const shipping = [
  {
    id: 1,
    title: "How do I track my order?",
    context: (
      <p>
        Check your email or go to the{" "}
        <strong>‘My Account → Order Details’</strong> page to find the tracking
        number. Use the link provided to track your order.
      </p>
    ),
  },
  {
    id: 2,
    title: "When will my order arrive?",
    context: (
      <p>
        For domestic orders, delivery takes 2–5 business days. For international
        orders, delivery may take 1–4 weeks. Made-to-order (MTO) products may
        take longer. Please check the product details page for specific delivery
        times for MTO items.
      </p>
    ),
  },
  {
    id: 3,
    title: "How much is shipping?",
    context: (
      <p>
        Shipping costs are calculated automatically after you add items to your
        cart and enter your address at checkout.
      </p>
    ),
  },
  {
    id: 4,
    title: "An item is missing from my order.",
    context: (
      <p>
        Sometimes we ship items separately. If there are multiple tracking
        numbers in your Order Details page, your order was split into multiple
        shipments. If that’s not the case, email us with the missing item’s name
        and your order number. We’ll resolve it quickly!
      </p>
    ),
  },
  {
    id: 5,
    title: "I received the wrong item.",
    context: (
      <p>
        Our apologies! Please email us a photo of the incorrect item along with
        your order number, and we’ll sort it out.
      </p>
    ),
  },
  {
    id: 6,
    title: "My item arrived damaged or defective.",
    context: (
      <p>
        If your item was damaged during shipping or has a defect not mentioned
        on the product page, send us a photo of the item along with your order
        number and name within 30 days of delivery to{" "}
        <a href="mailto:help-shop@mondayoff.me">help-shop@mondayoff.me</a>.
        We’ll assist with a replacement or refund.
      </p>
    ),
  },
];
