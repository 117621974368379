import React from "react";
import DOMPurify from "dompurify";
import { useEffect } from "react";
import { useState } from "react";
import { useProduct } from "../context/ProductContext";
import sizeGuide from "../img/sizeguide_T-shirt.png";
import styles from "./ProductDetailDecscription.module.scss";
import SizeTable from "./SizeTable";
export default function ProductDetailDecscription() {
  const { detailInfo, fabrics } = useProduct();
  const [description, setDescription] = useState("");
  const [sku, setSku] = useState("");

  useEffect(() => {
    const productSku = detailInfo?.variants?.edges[0]?.node?.sku.split("-");
    setSku(() =>
      productSku?.length ? `${productSku[0]}-${productSku[1]}` : ""
    );
    setDescription(DOMPurify.sanitize(detailInfo?.descriptionHtml));
  }, [
    detailInfo?.descriptionHtml,
    detailInfo?.id,
    detailInfo?.variants?.edges,
  ]);

  return (
    <section className={styles.container}>
      <div
        className={styles.description}
        dangerouslySetInnerHTML={{ __html: description }}
        style={{ whiteSpace: "pre-wrap" }}
      ></div>
      <div className={styles.inner}>
        <div className={styles.size_table}>
          <h3>Size(cm)</h3>
          <img src={sizeGuide} alt="guide" />
          <SizeTable />
        </div>
        <div className={styles.product_material}>
          <h3>Material</h3>
          {fabrics?.map((item) => (
            <p key={item.value}>{item?.value}</p>
          ))}
        </div>
        <div className={styles.product_no}>
          <h3>Product no.</h3>
          <p>{sku}</p>
        </div>
      </div>
    </section>
  );
}
