import React from "react";
import { useProduct } from "../context/ProductContext";
import styles from "./TotalPrice.module.scss";
export default function TotalPrice() {
  const { selectSize } = useProduct();

  return (
    <div className={styles.container}>
      <h3>Total</h3>
      <p>
        {selectSize.totalPrice.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        })}
      </p>
    </div>
  );
}
