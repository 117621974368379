import React, { useState, useCallback } from "react";
import styles from "./FAQList.module.scss";
import { ReactComponent as Line } from "../img/faq_line.svg";
import { ReactComponent as Plus } from "../img/plus.svg";
import { ReactComponent as Close } from "../img/close.svg";

export default function FAQList({ data, title }) {
  const [isSelected, setIsSelected] = useState(0);

  const onContentsClick = useCallback((id) => {
    setIsSelected((prevSelected) => (prevSelected === id ? 0 : id));
  }, []);

  return (
    <section className={styles.container}>
      <div className={styles.title}>
        <h3>{title}</h3>
        <Line />
      </div>
      <ul className={styles.lists}>
        {data.map((list) => (
          <li className={styles.contents_container} key={list.id}>
            <div
              className={`${styles.contents_title}  ${
                isSelected === list.id ? styles.active : styles.inactive
              }`}
              onClick={() => onContentsClick(list.id)}
            >
              <p>{list.title}</p>
              {isSelected === list.id ? <Close /> : <Plus />}
            </div>
            <div
              className={`${styles.contents} ${
                isSelected === list.id ? styles.active : styles.inactive
              }`}
            >
              {list.context}
            </div>
          </li>
        ))}
      </ul>
    </section>
  );
}
