import React, { useRef, useState, useEffect } from "react";
import styles from "./SignUp.module.scss";
import { ReactComponent as RedDotIcon } from "../img/red_dot.svg";
import { ReactComponent as EyeIcon } from "../img/eye-open.svg";
import { ReactComponent as EyeCloseIcon } from "../img/eye-close.svg";

import { ReactComponent as NotFoundIcon } from "../img/notFoundIcon.svg";
import { ReactComponent as ExsistIcon } from "../img/exsistIcon.svg";
import { useNavigate } from "react-router-dom";
import DateSelector from "../components/DateSelector";
import { useCustomer } from "../context/CustomerContext";
import LoadingSpinner from "../common/LoadingSpinner";
import { getCookie, setCookie } from "../cookie/cookie";
import { useCallback } from "react";

export default function SignUp() {
  const token = getCookie("access-token");

  const today = new Date();
  const navigate = useNavigate();
  const emailReg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const { createCustomer, login, addMetafields, checkGameId } = useCustomer();
  const [isPasswordViewClick, setIsPasswordViewClick] = useState(false);
  const [checkBirth, setCheckBirth] = useState("");
  const [isCreateClick, setIsCreateClick] = useState(false);
  const [isCheckGameIdClick, setIsCheckGameIdClick] = useState(false);
  const [formsData, setFormsData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    passwd: "",
    gameId: "",
  });
  const [isGameNickName, setisGameNickName] = useState({});
  const [selectedYear, setSelectedYear] = useState(today.getFullYear() - 18);
  const [selectedMonth, setSelectedMonth] = useState(today.getMonth() + 1);
  const [selectedDay, setSelectedDay] = useState(today.getDate());
  const [daysInMonth, setDaysInMonth] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState({
    year: false,
    month: false,
    day: false,
  });
  const [isPossibleBirth, setIsProssibleBirth] = useState(false);
  const passwdRef = useRef(null);
  const gameIdRef = useRef(null);
  const onPasswordViewClick = () => {
    setIsPasswordViewClick(!isPasswordViewClick);
  };
  useEffect(() => {
    token && navigate("/");
  }, [navigate, token]);
  useEffect(() => {
    const passwdLabel = document.getElementsByClassName(styles.passwd_label);
    const gameIdLabel = document.getElementsByClassName(styles.gameId_label);
    passwdRef.current.onfocus = function (e) {
      passwdLabel[0].style.border = "1px solid var(--gray-95, #2e2e2e)";
    };
    passwdRef.current.onblur = function (e) {
      passwdLabel[0].style.border = "1px solid var(--gray-10)";
    };
    gameIdRef.current.onfocus = function (e) {
      gameIdLabel[0].style.border = "1px solid var(--gray-95, #2e2e2e)";
    };
    gameIdRef.current.onblur = function (e) {
      gameIdLabel[0].style.border = "1px solid var(--gray-10)";
    };
  }, []);
  const onKeyDown = (e) => {
    if (e.key === "Backspace" && checkBirth.length > 8) {
      setCheckBirth(""); // 값 초기화
    }
  };
  const onChange = (e) => {
    const { value, name } = e.target;
    switch (name) {
      case "firstName":
        setFormsData({ ...formsData, firstName: value });
        return;
      case "lastName":
        setFormsData({ ...formsData, lastName: value });
        return;
      case "email":
        setFormsData({ ...formsData, email: value });
        return;
      case "password":
        setFormsData({ ...formsData, passwd: value });
        return;
      case "birth":
        var year = value.substring(4, 8);
        var month = value.substring(2, 4);
        var day = value.substring(0, 2);

        if (Number(year) > 2005 || value?.length !== 8) {
          setIsProssibleBirth(false);
          setCheckBirth(
            value.replace(/\d{2}\d{2}\d{4}/g, `${day}/${month}/${year}`)
          );
        } else {
          setIsProssibleBirth(true);
          setCheckBirth(
            value.replace(/\d{2}\d{2}\d{4}/g, `${day}/${month}/${year}`)
          );
        }

        return;
      case "gameId":
        setFormsData({ ...formsData, gameId: value });
        return;
      default:
        return setFormsData({});
    }
  };

  useEffect(() => {
    if (isCheckGameIdClick && formsData?.gameId?.length) {
      checkGameId(formsData.gameId).then((res) => {
        if (isCheckGameIdClick) {
          setisGameNickName(res);
          document.body.classList.add("no-scroll");
        }
      });
    }
  }, [checkGameId, formsData.gameId, isCheckGameIdClick]);
  const onCheckIdClick = () => {
    if (formsData.gameId.length) {
      setIsCheckGameIdClick(true);
    }
  };

  const onCheckGameIdConfirmClick = () => {
    setIsCheckGameIdClick(false);
    document.body.classList.remove("no-scroll");
  };
  const onCheckGameIdCancelClick = () => {
    setIsCheckGameIdClick(false);
    gameIdRef.current.value = "";
    document.body.classList.remove("no-scroll");
    setFormsData((prev) => ({ ...prev, gameId: "" }));
  };
  const onCreateClick = async () => {
    setIsCreateClick(true);

    if (!formsData?.firstName?.length) {
      alert("Check your First Name");
      setIsCreateClick(false);
    } else if (!formsData?.lastName?.length) {
      setIsCreateClick(false);
      alert("Check your Last Name");
    } else if (!formsData?.email?.length || !emailReg.test(formsData.email)) {
      setIsCreateClick(false);
      alert("Check your Email");
    } else if (!formsData?.passwd.length) {
      setIsCreateClick(false);
      alert("Check your Password");
    } else if (!isPossibleBirth) {
      return;
    } else {
      await createCustomer(formsData)
        .then((res) => {
          if (res?.customerCreate?.customer?.id && formsData.gameId) {
            addMetafields(res?.customerCreate?.customer?.id, formsData.gameId);
          }
        })
        .then(
          async () =>
            await login(formsData).then((res) => {
              return setCookie(
                "access-token",
                res?.customerAccessTokenCreate?.customerAccessToken
                  ?.accessToken,
                {
                  expires: new Date(
                    res?.customerAccessTokenCreate?.customerAccessToken?.expiresAt
                  ),
                  httpOnly: false,
                  path: "/",
                  secure: true,
                  sameSite: "None",
                }
              );
            })
        )
        .then(
          () => getCookie("access-token") && navigate("/", { replace: true })
        );
    }
  };

  return (
    <div className={styles.container}>
      {isCheckGameIdClick && (
        <>
          <div className={`${styles.overlay} ${styles.active}`}>
            <div className={styles.modal_container}>
              <section className={styles.top_section}>
                {isGameNickName?.exists ? <ExsistIcon /> : <NotFoundIcon />}

                <div>
                  <h3>
                    {isGameNickName?.exists
                      ? "Verify Game ID"
                      : "The Game ID Does not Exist"}
                  </h3>
                  {isGameNickName?.exists ? (
                    <p>
                      Is <strong>{isGameNickName?.useName}</strong> the nickname
                      you use in Dino: Mutant?
                    </p>
                  ) : (
                    <p>Please check your Game ID.</p>
                  )}
                </div>
              </section>
              <section className={styles.btn_container}>
                <button onClick={onCheckGameIdCancelClick}>Cancel</button>
                <button onClick={onCheckGameIdConfirmClick}>Confirm</button>
              </section>
            </div>
          </div>
        </>
      )}
      <div className={styles.inner}>
        <h2>Create account</h2>
        <section className={styles.forms_container}>
          <div className={styles.forms}>
            <div className={styles.forms_title}>
              <p>First name</p>
              <RedDotIcon />
            </div>
            <input type="text" name="firstName" onChange={onChange} />
          </div>
          <div className={styles.forms}>
            <div className={styles.forms_title}>
              <p>Last name</p>
              <RedDotIcon />
            </div>
            <input type="text" name="lastName" onChange={onChange} />
          </div>
          <div className={styles.forms}>
            <div className={styles.forms_title}>
              <p>Email</p>
              <RedDotIcon />
            </div>
            <input type="email" name="email" onChange={onChange} />
          </div>
          <div className={styles.forms}>
            <div className={styles.forms_title}>
              <p>password</p>
              <RedDotIcon />
            </div>
            <label className={styles.passwd_label}>
              <input
                type={isPasswordViewClick ? "text" : "password"}
                ref={passwdRef}
                onChange={onChange}
                name="password"
              />
              {isPasswordViewClick ? (
                <EyeCloseIcon onClick={onPasswordViewClick} />
              ) : (
                <EyeIcon onClick={onPasswordViewClick} />
              )}
            </label>
          </div>
          <div className={styles.forms}>
            <div className={styles.forms_title}>
              <p
                data-content={
                  !isPossibleBirth && checkBirth?.length === 10
                    ? "inactive"
                    : ""
                }
              >
                Date of Birth
              </p>
              <RedDotIcon />
            </div>
            <input
              type="text"
              name="birth"
              onChange={onChange}
              value={checkBirth}
              maxLength={10}
              onKeyDown={onKeyDown}
              placeholder="DD/MM/YYYY"
              data-content={
                !isPossibleBirth && checkBirth?.length === 10 ? "inactive" : ""
              }
            />
            <p
              className={styles.birth_noti}
              data-content={
                !isPossibleBirth && checkBirth?.length === 10 ? "inactive" : ""
              }
            >
              You gotta be 18 or older to join the fun!
            </p>
            {/* 
           모바일 용
           <DateSelector
              selectedYear={selectedYear}
              setSelectedYear={setSelectedYear}
              selectedMonth={selectedMonth}
              setSelectedMonth={setSelectedMonth}
              selectedDay={selectedDay}
              setSelectedDay={setSelectedDay}
              daysInMonth={daysInMonth}
              setDaysInMonth={setDaysInMonth}
              setIsDropdownOpen={setIsDropdownOpen}
              isDropdownOpen={isDropdownOpen}
              today={today}
           />
           */}
          </div>
          <div className={styles.forms}>
            <div>
              <p>Game ID(Option)</p>
            </div>
            <label className={styles.gameId_label}>
              <input
                type="text"
                name="gameId"
                onChange={onChange}
                ref={gameIdRef}
              />
              <button
                className={styles.gameId_check_btn}
                onClick={onCheckIdClick}
              >
                Check
              </button>
            </label>
          </div>
        </section>

        <div
          className={styles.signup_Btn}
          onClick={
            !isCreateClick && isPossibleBirth ? onCreateClick : undefined
          }
          data-content={!isPossibleBirth && "inactive"}
        >
          {isCreateClick && isPossibleBirth ? <LoadingSpinner /> : "Create"}
        </div>
        <div className={styles.signup_term_policy}>
          <a href="https://mondayoffshop.com/policies/terms-of-service">
            Terms
          </a>
          <a href="https://mondayoffshop.com/policies/privacy-policy">
            Privacy policy
          </a>
        </div>
      </div>
    </div>
  );
}
