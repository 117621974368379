import ReactGA from "react-ga4";
export const pageView = (title, pathName) => {
  ReactGA.send({ hitType: "pageview", title, page: pathName });
};

export const clickEvent = (buttonId, buttonText, pathName) => {
  ReactGA.event({
    name: "btn_click",
    category: buttonId,
    action: buttonText,
    page: pathName,
  });
};

export const linkClickEvent = (title, action, pathName) => {
  ReactGA.event({
    name: "link_click",
    category: title,
    action,
    page: pathName,
  });
};
