import React, { useState, useRef, useEffect } from "react";
import DatePicker from "react-datepicker";
import { DateRangePicker } from "react-date-range";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useNavigate } from "react-router-dom";
import OrderHistory from "../components/OrderHistory";
import { useOrderList } from "../context/OrderListContext";
import styles from "./MyPage.module.scss";
import moment from "moment";
import { ReactComponent as DateRangeClickIcon } from "../img/dateRangeClick.svg";
import { ReactComponent as CalanderIcon } from "../img/calander.svg";
import { getCookie } from "../cookie/cookie";
import useWindowSize from "../custom/useWindowSize";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
export default function MyPage() {
  const { width } = useWindowSize();
  const today = moment().format();
  const navigate = useNavigate();
  const dateRangeRef = useRef(null);
  const token = getCookie("access-token");
  const { orderList } = useOrderList();
  const [itemList, setItemList] = useState([]);
  const [first, setFirst] = useState(5);
  const [isDateRangeBtnClick, setIsDateRangeBtnClick] = useState(false);
  const [datePick, setDatePick] = useState({
    startDate: moment(today).subtract(3, "month").format(),
    endDate: today,
    range: 3,
  });

  const [dateRange, setDateRange] = useState({
    startDate: today,
    endDate: today,
    key: "selection",
  });
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  useEffect(() => {
    !token && navigate("/login");
  }, [navigate, token]);
  useEffect(() => {
    orderList(first).then((res) =>
      setItemList(
        res?.customer?.orders?.edges?.filter((item) =>
          moment(item?.node?.processedAt).isBetween(
            datePick.startDate,
            datePick.endDate,
            "[]"
          )
        )
      )
    );
  }, [datePick.endDate, datePick.startDate, first, orderList]);
  const onReadMoreClick = () => {
    setFirst((prev) => prev + 5);
  };

  const onDatePickClick = (range) => {
    if (range === 1) {
      const startDate = moment(today).subtract(1, "month").format();
      setDatePick((prev) => ({ ...prev, startDate, range: 1 }));
    } else if (range === 3) {
      const startDate = moment(today).subtract(3, "month").format();
      setDatePick((prev) => ({ ...prev, startDate, range: 3 }));
    } else if (range === 6) {
      const startDate = moment(today).subtract(6, "month").format();
      setDatePick((prev) => ({ ...prev, startDate, range: 6 }));
    }
  };
  const onDateRangeSelectBtnClick = () => {
    setIsDateRangeBtnClick((prev) => !prev);
  };
  const onDateRangeClick = (type) => {
    if (type === "pc") {
      orderList(first).then((res) =>
        setItemList(
          res?.customer?.orders?.edges?.filter((item) =>
            moment(item?.node?.processedAt).isBetween(
              dateRange.startDate,
              dateRange.endDate,
              "[]"
            )
          )
        )
      );
    } else {
      setIsDateRangeBtnClick(false);
      orderList(first).then((res) =>
        setItemList(
          res?.customer?.orders?.edges?.filter((item) =>
            moment(item?.node?.processedAt).isBetween(
              state[0].startDate,
              state[0].endDate,
              "[]"
            )
          )
        )
      );
    }
  };
  const onDateRangeChange = (item) => {
    setState([item.selection]);
  };

  return (
    <div className={styles.container}>
      <section className={styles.top_container}>
        <h2>My Account</h2>
        <span>
          <Link to="/mypage/profile">Edit Profile</Link>
        </span>
      </section>
      <section className={styles.bottom_container}>
        <div>
          <span className={styles.bottom_title}>
            <p>Order History</p>
            <p>(Last 3 months)</p>
          </span>
          <span className={styles.bottom_date_picker}>
            <div className={styles.range_btns}>
              <button
                onClick={() => onDatePickClick(1)}
                data-content={datePick.range === 1 ? "active" : ""}
              >
                1 Month
              </button>
              <button
                onClick={() => onDatePickClick(3)}
                data-content={datePick.range === 3 ? "active" : ""}
              >
                3 Months
              </button>
              <button
                onClick={() => onDatePickClick(6)}
                data-content={datePick.range === 6 ? "active" : ""}
              >
                6 Months
              </button>
            </div>

            <div className={styles.date_range_picker_contianer}>
              {width > 769 ? (
                <>
                  <label className={styles.startDate_label}>
                    <DatePicker
                      id="startDate_picker"
                      name="startDate"
                      maxDate={dateRange.endDate}
                      onChange={(date) =>
                        setDateRange((prev) => ({
                          ...prev,
                          startDate: moment(date).format(),
                        }))
                      }
                      selected={dateRange.startDate}
                    />

                    <div className={styles.date}>
                      <p>
                        {moment(dateRange.startDate).format("MMM DD. YYYY")}
                      </p>
                      <CalanderIcon />
                    </div>
                  </label>
                  <p>-</p>
                  <label className={styles.endDate_label}>
                    <DatePicker
                      id="endDate_picker"
                      minDate={dateRange.startDate}
                      maxDate={dateRange.endDate}
                      onChange={(date) =>
                        setDateRange((prev) => ({
                          ...prev,
                          endDate: moment(date).format(),
                        }))
                      }
                      selected={dateRange.endDate}
                    />

                    <div className={styles.date}>
                      <p>{moment(dateRange.endDate).format("MMM DD. YYYY")}</p>
                      <CalanderIcon />
                    </div>
                  </label>
                  <DateRangeClickIcon
                    onClick={() => onDateRangeClick("pc")}
                    className={styles.date_range_click}
                  />
                </>
              ) : (
                <>
                  <label className={styles.mobile_date_picker_container}>
                    <DateRangePicker
                      className={`${styles.date_range_picker} ${
                        isDateRangeBtnClick ? styles.active : styles.inactive
                      }`}
                      editableDateInputs={true}
                      onChange={(item) => onDateRangeChange(item)}
                      moveRangeOnFirstSelection={false}
                      ranges={state}
                      ref={dateRangeRef}
                    />
                    <div
                      className={styles.selected_date_range}
                      onClick={onDateRangeSelectBtnClick}
                    >
                      <p>
                        {moment(state[0].startDate).format("MMM. DD. YYYY")}-
                        {moment(state[0].endDate).format("MMM. DD. YYYY")}
                      </p>
                      <CalanderIcon />
                    </div>
                  </label>
                  <DateRangeClickIcon
                    onClick={onDateRangeClick}
                    className={styles.date_range_click}
                  />
                </>
              )}
            </div>
          </span>
        </div>
      </section>
      <ul className={styles.orderList_container}>
        {itemList?.map((item) => (
          <OrderHistory item={item} />
        ))}
      </ul>
      <button className={styles.readMore_btn} onClick={onReadMoreClick}>
        Read more
      </button>
    </div>
  );
}
