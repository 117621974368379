import React from "react";
import ReactDOM from "react-dom/client";

import "./index.scss";
import App from "./App";
import { CookiesProvider } from "react-cookie";
import { BrowserRouter } from "react-router-dom";
import { CartProvider } from "./context/CartContext";
import CartService from "./service/cart";
import ReactGA from "react-ga4";
import ScrollToTop from "./common/ScrollToTop";
const MEASUREMENT_ID = process.env.REACT_APP_MONOFF_GA4_MEASUREMENT_ID;
ReactGA.initialize(MEASUREMENT_ID);

const cartService = new CartService();
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <CookiesProvider>
      <CartProvider cartService={cartService}>
        <ScrollToTop>
          <App />
        </ScrollToTop>
      </CartProvider>
    </CookiesProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
