import "./App.module.scss";
import { ProductProvider } from "./context/ProductContext";
import { CustomerProvider } from "./context/CustomerContext";

import { useCart } from "./context/CartContext";
import Router from "./Router";
import styles from "./App.module.scss";
import Header from "./components/Header";
import Footer from "./components/Footer";
import CartService from "./service/cart";
import ProductService from "./service/product";
import OrderListService from "./service/orderList";
import CustomerService from "./service/customer";

import { useEffect, useState } from "react";
import { getCookie, setCookie } from "./cookie/cookie";
import { useLocation } from "react-router-dom";
import { remove } from "lodash";
const productService = new ProductService();
const cartService = new CartService();
const customerService = new CustomerService();
const orderListService = new OrderListService();

function App() {
  const { createCart, getCart } = useCart();
  const location = useLocation();
  const [nullCheck, setNullCheck] = useState(null);
  const [isCartCookie, setIsCartCookie] = useState(false);
  const cartId = getCookie("cartId");
  useEffect(() => {
    setIsCartCookie(!!cartId);
  }, [location]);
  useEffect(() => {
    if (isCartCookie) {
      getCart(cartId).then((res) => setNullCheck(res.cart));
      if (!nullCheck) {
        remove("cartId");
        createCart().then((res) =>
          setCookie("cartId", res?.cartCreate?.cart?.id, {
            withCredentials: true,
            httpOnly: true,
            secure: true,
            sameSite: "None",
          })
        );
      }
    } else {
      createCart().then((res) =>
        setCookie("cartId", res?.cartCreate?.cart?.id, {
          withCredentials: true,
          httpOnly: true,
          secure: true,
          sameSite: "None",
        })
      );
    }
  }, [createCart.location]);
  return (
    <div className={styles.container}>
      <CustomerProvider customerService={customerService}>
        <ProductProvider productService={productService}>
          <Header />
        </ProductProvider>
        <Router
          productService={productService}
          cartService={cartService}
          customerService={customerService}
          orderListService={orderListService}
        />
      </CustomerProvider>
      <Footer />
    </div>
  );
}

export default App;
