import React, {
  createContext,
  createRef,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { clickEvent } from "../api/ga4";

const CartContext = createContext({});
const contextRef = createRef();
export function CartProvider({ cartService, children }) {
  const [isCart, setIsCart] = useState(false);
  const [itemCount, setItemCount] = useState(0);
  const createCart = useCallback(
    async () => await cartService.createCart().then((res) => res),
    [cartService]
  );
  const addCart = useCallback(
    (cartId, varitantId, qty) =>
      cartService.addCart(cartId, varitantId, qty).then((res) => res),
    [cartService]
  );
  const getCart = useCallback(
    async (cartId) => await cartService.getCart(cartId).then((res) => res),
    [cartService]
  );
  const updateCart = useCallback(
    async (cartId, lineId, qty) =>
      await cartService.updateCart(cartId, lineId, qty).then((res) => res),
    [cartService]
  );
  const deleteCart = useCallback(
    async (cartId, lineId) => await cartService.deleteCart(cartId, lineId),
    [cartService]
  );
  const onisCartClick = useCallback(() => {
    clickEvent("cart", "Cart Click", window.location.pathname);
    setIsCart(!isCart);
    if (!isCart) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }, [isCart]);
  const context = useMemo(
    () => ({
      createCart,
      getCart,
      isCart,
      setIsCart,
      onisCartClick,
      addCart,
      updateCart,
      deleteCart,
      itemCount,
      setItemCount,
    }),
    [
      createCart,
      getCart,
      isCart,
      onisCartClick,
      addCart,
      updateCart,
      deleteCart,
      itemCount,
      setItemCount,
    ]
  );
  return (
    <CartContext.Provider value={context}>{children}</CartContext.Provider>
  );
}

export default CartContext;
export const fetchToken = () => contextRef.current;
export const useCart = () => useContext(CartContext);
