import React, { useState } from "react";
import styles from "./Header.module.scss";
import logo from "../img/mondayoff_new_logo.png";
import { ReactComponent as GlassIcon } from "../img/glass.svg";
import { ReactComponent as MyPageIcon } from "../img/person.svg";
import { ReactComponent as CartIcon } from "../img/cart.svg";
import { Link, useLocation } from "react-router-dom";
import { useProduct } from "../context/ProductContext";
import Cart from "./Cart";
import { useCart } from "../context/CartContext";
import { getCookie } from "../cookie/cookie";
import { useEffect } from "react";
import { useCustomer } from "../context/CustomerContext";
import useWindowSize from "../custom/useWindowSize";
import { clickEvent, linkClickEvent } from "../api/ga4";
export default function Header() {
  const { selectSize } = useProduct();
  const cartId = getCookie("cartId");
  const location = useLocation();
  const [isCartCookie, setIsCartCookie] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { onisCartClick, isCart, getCart, itemCount, setItemCount } = useCart();
  const { width } = useWindowSize();
  useEffect(() => {
    setIsCartCookie(!!cartId);
  }, [location]);
  useEffect(() => {
    isCartCookie &&
      getCart(cartId).then((res) =>
        setItemCount(res?.cart?.lines?.edges?.length)
      );
  }, [cartId, getCart, setItemCount, isCartCookie]);

  useEffect(() => {
    const token = getCookie("access-token");
    setIsLoggedIn(!!token);
  }, [location]);
  const onLinkClick = (btnId, btnText, pathName) => {
    linkClickEvent(btnId, btnText, pathName);
  };
  return (
    <div className={styles.container}>
      <div className={styles.header_inner}>
        <Link
          to="/"
          className={styles.header_logo}
          onClick={() => onLinkClick("Home", "to home", location.pathname)}
        >
          <img src={logo} alt="MondayOFF_logo" />
          <p>Shop</p>
        </Link>
        {width < 768 || width < 1440 ? (
          <>
            <Link
              to={isLoggedIn ? "/mypage" : "/login"}
              className={styles.mypage_icon}
              onEventClick={() =>
                onLinkClick(
                  "Home",
                  isLoggedIn ? "To mypage" : "To login",
                  isLoggedIn ? "/mypage" : "/login"
                )
              }
            >
              <MyPageIcon />
            </Link>
            {/* <GlassIcon /> */}
            <span className={styles.cart_icon}>
              <div>{itemCount}</div>
              <CartIcon onClick={onisCartClick} />
            </span>
          </>
        ) : (
          <span className={styles.header_consumer}>
            <Link
              className={styles.mypage_icon}
              to={isLoggedIn ? "/mypage" : "/login"}
              onEventClick={() =>
                onLinkClick(
                  "Home",
                  isLoggedIn ? "To mypage" : "To login",
                  isLoggedIn ? "/mypage" : "/login"
                )
              }
            >
              <MyPageIcon />
            </Link>
            {/* <GlassIcon /> */}
            <span className={styles.cart_icon}>
              <div>{itemCount}</div>
              <CartIcon onClick={onisCartClick} />
            </span>
          </span>
        )}
      </div>
      {isCart && (
        <>
          <div
            className={`${styles.overlay} ${isCart ? styles.active : ""}`}
          ></div>
          <Cart />
        </>
      )}
    </div>
  );
}
