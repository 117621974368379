import React from "react";

import styles from "./ProductDetailSize.module.scss";
import { ReactComponent as Plus } from "../img/plus.svg";
import { ReactComponent as Minus } from "../img/minus.svg";
import { useProduct } from "../context/ProductContext";

export default function ProductDetailSize() {
  const { sizeQty, selectSize, setSelectSize } = useProduct();

  const onClick = (select) => {
    setSelectSize({
      id: select.id,
      img: select.img,
      size: select.size,
      totalQty: select.qty,
      qty: 1,
      totalPrice: Number(select.totalPrice),
      price: Number(select.price),
    });
  };

  const onQtyClick = (type) => {
    if (!selectSize?.size?.length) {
      return;
    }
    if (type === "plus") {
      if (selectSize.totalQty === selectSize.qty) {
      } else {
        setSelectSize((prev) => ({
          ...prev,
          qty: selectSize.qty + 1,
          totalPrice: selectSize.totalPrice + selectSize.price,
        }));
      }
    } else {
      if (selectSize.qty === 1) {
      } else {
        setSelectSize((prev) => ({
          ...prev,
          qty: selectSize.qty - 1,
          totalPrice: selectSize.totalPrice - selectSize.price,
        }));
      }
    }
  };
  return (
    <section className={styles.container}>
      <div className={styles.size_inner}>
        <p>Size</p>
        <ul className={styles.size_list}>
          {sizeQty?.map((item) => (
            <li
              key={item?.size}
              content={!item.qty && "soldout"}
              data-content={
                selectSize.size === item.size ? "active" : "inactive"
              }
              onClick={() => (item.qty ? onClick(item) : "")}
            >
              {item.size}
            </li>
          ))}
        </ul>
        <div className={styles.select_qty}>
          <p>Qty</p>
          <div className={styles.select_box}>
            <Minus onClick={() => onQtyClick("minus")} />
            <p>{selectSize.qty}</p>
            <Plus onClick={() => onQtyClick("plus")} />
          </div>
        </div>
      </div>
    </section>
  );
}
