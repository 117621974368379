import React, {
  createContext,
  createRef,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { getCookie } from "../cookie/cookie";

const CustomerContext = createContext({});
const contextRef = createRef();
export function CustomerProvider({ customerService, children }) {
  const [isCookie, setIsCookie] = useState(false);
  useEffect(() => {
    const cookie = getCookie("access-token");
    if (cookie?.length) {
      setIsCookie(true);
    } else {
      setIsCookie(false);
    }
  }, []);
  const login = useCallback(
    (customerData) =>
      customerService
        .login(customerData)
        .then((res) => res)
        .catch((e) => e),
    [customerService]
  );
  const getCustomer = useCallback(
    async () =>
      await customerService
        .getCustomer()
        .then((res) => res)
        .catch((e) => e),
    [customerService]
  );
  const createCustomer = useCallback(
    (customerData) =>
      customerService
        .createCustomer(customerData)
        .then((res) => res)
        .catch((e) => e),
    [customerService]
  );
  const updateCustomer = useCallback(
    async (customerData) =>
      await customerService
        .updateCustomer(customerData)
        .then((res) => res)
        .catch((e) => e),
    [customerService]
  );
  const addMetafields = useCallback(
    (customerId, customerData) =>
      customerService
        .addMetafields(customerId, customerData)
        .then((res) => res)
        .catch((e) => e),
    [customerService]
  );
  const checkGameId = useCallback(
    (gameId) => customerService.checkGameId(gameId),
    [customerService]
  );

  const customerPayment = useCallback(
    async (orderId) =>
      await customerService
        .customerPayment(orderId)
        .then((res) => res)
        .catch((e) => e),
    [customerService]
  );
  const resetPasswd = useCallback(
    async (email) =>
      await customerService
        .resetPasswd(email)
        .then((res) => res)
        .catch((e) => e),
    [customerService]
  );
  const sendContactMail = useCallback(
    async (data) => {
      return await customerService
        .sendContactMail(data)
        .then((res) => res)
        .catch((e) => e);
    },
    [customerService]
  );
  const context = useMemo(
    () => ({
      login,
      createCustomer,
      addMetafields,
      checkGameId,
      isCookie,
      customerPayment,
      getCustomer,
      updateCustomer,
      resetPasswd,
      sendContactMail,
    }),
    [
      login,
      createCustomer,
      addMetafields,
      checkGameId,
      isCookie,
      customerPayment,
      getCustomer,
      updateCustomer,
      resetPasswd,
      sendContactMail,
    ]
  );
  return (
    <CustomerContext.Provider value={context}>
      {children}
    </CustomerContext.Provider>
  );
}

export default CustomerContext;
export const fetchToken = () => contextRef.current;
export const useCustomer = () => useContext(CustomerContext);
