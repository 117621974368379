import React from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import { linkClickEvent, pageView } from "../api/ga4";
import { getCookie } from "../cookie/cookie";
import styles from "./ResetPasswdSubmit.module.scss";
export default function ResetPasswdSubmit() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const location = useLocation();
  const token = getCookie("access-token");
  useEffect(() => {
    pageView("submit send password", location.pathname);
  }, [location]);
  useEffect(() => {
    token && navigate("/");
  }, [navigate, token]);
  return (
    <div className={styles.container}>
      <div className={styles.inner}>
        <h2>Check your email</h2>
        <div>
          <p>We sent a password reset link to</p>
          <p>{state?.email}</p>
        </div>
        <Link
          to="/login"
          onClick={() =>
            linkClickEvent("back-btn", "To Login", location.pathname)
          }
        >
          Back to Login
        </Link>
      </div>
    </div>
  );
}
