import React, {
  createContext,
  createRef,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

const ProductContext = createContext({});
const contextRef = createRef();
export function ProductProvider({ productService, children }) {
  const [detailInfo, setDetailInfo] = useState({});
  const [detailImages, setDetailImages] = useState([]);
  const [size, setSize] = useState([]);
  const [fabrics, setFabrics] = useState([]);
  const [sizeQty, setSizeQty] = useState([
    {
      id: "",
      img: "",
      size: "",
      qty: 0,
      price: "",
      totalPrice: 0,
    },
  ]);

  const [selectSize, setSelectSize] = useState({
    id: "",
    img: "",
    size: "",
    totalQty: 0,
    qty: 1,
    price: "",
    totalPrice: 0,
  });

  useEffect(() => {
    productService
      .getProductDetail()
      .then((res) => {
        setDetailImages(res?.product?.images?.edges);
        setDetailInfo(res.product);
      })
      .then(() =>
        productService
          .getFabricList(detailInfo?.metafield?.value)
          .then((res) =>
            setFabrics(
              res?.nodes?.map((list) =>
                list?.fields?.find((item) => item?.value)
              )
            )
          )
      );
  }, [detailInfo?.metafield?.value, productService]);

  useEffect(() => {
    setSize(() =>
      detailInfo?.variants?.edges?.map((list) => list?.node?.title)
    );

    setSizeQty(
      detailInfo?.variants?.edges?.map((list) => ({
        id: list?.node?.id,
        img: detailInfo?.images?.edges[0].node.src,
        size: list?.node?.title,
        qty: list?.node?.quantityAvailable,
        price: list?.node?.price?.amount,
        totalPrice: list?.node?.price?.amount,
      }))
    );
  }, [detailInfo?.images?.edges, detailInfo?.variants?.edges, size?.length]);
  const context = useMemo(
    () => ({
      detailInfo,
      detailImages,
      size,
      sizeQty,
      selectSize,
      setSelectSize,
      fabrics,
    }),
    [detailInfo, detailImages, size, sizeQty, selectSize, fabrics]
  );
  return (
    <ProductContext.Provider value={context}>
      {children}
    </ProductContext.Provider>
  );
}

export default ProductContext;
export const fetchToken = () => contextRef.current;
export const useProduct = () => useContext(ProductContext);
