import React from "react";
import styles from "./SizeTable.module.scss";
export default function SizeTable() {
  return (
    <table className={styles.container}>
      <tr>
        <th className={styles.table_title}>Size</th>
        <th className={styles.size}>XS</th>
        <th className={styles.size}>S</th>
        <th className={styles.size}>M</th>
        <th className={styles.size}>L</th>
        <th className={styles.size}>XL</th>
        <th className={styles.size}>2XL</th>
        <th className={styles.size}>3XL</th>
      </tr>

      <tr>
        <th className={styles.table_title}>Length</th>
        <td className={styles.size_length}>63</td>
        <td className={styles.size_length}>66</td>
        <td className={styles.size_length}>70</td>
        <td className={styles.size_length}>74</td>
        <td className={styles.size_length}>78</td>
        <td className={styles.size_length}>82</td>
        <td className={styles.size_length}>86</td>
      </tr>
      <tr>
        <th className={styles.table_title}>Body width</th>
        <td className={styles.size_length}>46</td>
        <td className={styles.size_length}>49</td>
        <td className={styles.size_length}>52</td>
        <td className={styles.size_length}>55</td>
        <td className={styles.size_length}>58</td>
        <td className={styles.size_length}>61</td>
        <td className={styles.size_length}>64</td>
      </tr>

      <tr>
        <th className={styles.table_title}>Sleeve length</th>
        <td className={styles.size_length}>18</td>
        <td className={styles.size_length}>19</td>
        <td className={styles.size_length}>20</td>
        <td className={styles.size_length}>22</td>
        <td className={styles.size_length}>24</td>
        <td className={styles.size_length}>26</td>
        <td className={styles.size_length}>26</td>
      </tr>
    </table>
  );
}
