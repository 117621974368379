import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { OrderListProvider } from "./context/OrderListContext";
import { ProductProvider } from "./context/ProductContext";
import ContactUs from "./page/ContactUs";
import EditProfile from "./page/EditProfile";
import FAQ from "./page/FAQ";
import Home from "./page/Home";
import Login from "./page/Login";
import MyPage from "./page/MyPage";
import OrderDetail from "./page/OrderDetail";

import ProductDetail from "./page/ProductDetail";
import ResetPasswdSubmit from "./page/ResetPasswdSubmit";
import SendResetPasswd from "./page/SendResetPasswd";
import SignUp from "./page/SignUp";
import SubmissionEmail from "./page/SubmissionEmail";

export default function Router({ productService, orderListService }) {
  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/passwd/reset" element={<SendResetPasswd />} />
      <Route path="/passwd/reset/submit" element={<ResetPasswdSubmit />} />
      <Route
        path="/mypage"
        element={
          <OrderListProvider orderListService={orderListService}>
            <MyPage />
          </OrderListProvider>
        }
      />
      <Route
        path="/mypage/detail"
        element={
          <OrderListProvider orderListService={orderListService}>
            <OrderDetail />
          </OrderListProvider>
        }
      />
      <Route path="/mypage/profile" element={<EditProfile />} />
      <Route
        path="/product/detail"
        element={
          <ProductProvider productService={productService}>
            <ProductDetail />
          </ProductProvider>
        }
      />
      <Route path="/faq" element={<FAQ />} />
      <Route path="/contact" element={<ContactUs />} />
      <Route path="/contact/submission" element={<SubmissionEmail />} />
      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  );
}
