const subjectList = [
  {
    id: 1,
    title: "Order",
  },
  {
    id: 2,
    title: "Shipping",
  },
];
export default subjectList;
