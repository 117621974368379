import { graphqlRequest } from "../api/api";
import { getCookie } from "../cookie/cookie";
import axios from "axios";
export default class OrderListService {
  async orderList(first) {
    const accessToken = getCookie("access-token");
    let data = JSON.stringify({
      query: `query GetCustomerOrders($customerAccessToken: String!, $first: Int!) {
        customer(customerAccessToken: $customerAccessToken) {
          orders(first: $first,sortKey: PROCESSED_AT, reverse: true) {
            edges {
              node {
                id
                name
                orderNumber
                processedAt
                financialStatus
                fulfillmentStatus
                billingAddress{
                    address1
                    zip
                    name
                    firstName
                    lastName
                    city
                    country
                    phone
                    province
                  }
                  shippingAddress{
                    address1
                    zip
                    name
                    firstName
                    lastName
                    city
                    country
                    phone
                    province
                  }
                  subtotalPrice{
                    amount
                  }
                totalPrice {
                  amount
                  currencyCode
                }
                totalShippingPrice {
                    amount
                    currencyCode
                  }
                  totalTax{
                    amount
                    currencyCode
                  }
                lineItems(first: 10) {
                  edges {
                    node {
                      title
                      quantity
                      originalTotalPrice{
                          amount
                      }
                      variant {
                        id
                        title
                        image {
                          url
                          altText
                        }
                        selectedOptions {
                          name
                          value
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
       `,
      variables: { customerAccessToken: accessToken, first },
    });
    return await graphqlRequest(data);
  }
  async orderDetail(orderNo) {
    try {
      return await axios(
        `${process.env.REACT_APP_MONOFF_SERVER_URL}/shopify/order/detail`,
        {
          method: "post",
          data: {
            orderNo,
          },
        }
      ).then((res) => res.data);
    } catch (e) {
      throw new Error(e);
    }
  }
  async orderDetailFulfillment(orderNo) {
    try {
      return await axios(
        `${process.env.REACT_APP_MONOFF_SERVER_URL}/shopify/order/detail/fulfillment`,
        {
          method: "post",
          data: {
            orderNo,
          },
        }
      ).then((res) => res.data);
    } catch (e) {
      throw new Error(e);
    }
  }
  async orderCancel(orderId) {
    try {
      return await axios(
        `${process.env.REACT_APP_MONOFF_SERVER_URL}/shopify/order/detail/product/cancel`,
        {
          method: "post",
          data: {
            orderId,
          },
        }
      ).then((res) => res.data);
    } catch (e) {
      throw new Error(e);
    }
  }
}
