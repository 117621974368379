import React, { useEffect, useState } from "react";
import styles from "./Cart.module.scss";
import { ReactComponent as CloseIcon } from "../img/close.svg";
import { getCookie, setCookie } from "../cookie/cookie";
import { useCart } from "../context/CartContext";
import { ReactComponent as PlusIcon } from "../img/plus.svg";
import { ReactComponent as MinusIcon } from "../img/minus.svg";
import { ReactComponent as TrashCanIcon } from "../img/trashcan.svg";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import { useRef } from "react";
import { useLocation } from "react-router-dom";
import { linkClickEvent } from "../api/ga4";

export default function Cart() {
  const cartId = getCookie("cartId");
  const cartRef = useRef(null);
  const location = useLocation();
  const { createCart } = useCart();
  const customerCookie = getCookie("access-token");
  const [isCartCookie, setIsCartCookie] = useState(false);
  useEffect(() => {
    setIsCartCookie(!!cartId);
  }, [cartId, location]);

  const {
    isCart,
    getCart,
    onisCartClick,
    updateCart,
    deleteCart,
    setIsCart,

    setItemCount,
  } = useCart();

  const [isEmptyCart, setIsEmptyCart] = useState(false);
  const [checkoutUrl, setCheckoutUrl] = useState("");
  const [cartList, setCartList] = useState([
    {
      lineId: "",
      variantId: "",
      size: "",
      image: "",
      productName: "",
      qty: 0,
      price: "",
    },
  ]);

  const [cartTotalPrice, setCartTotalPrice] = useState("");
  useEffect(() => {
    if (isCartCookie) {
      getCart(cartId).then((res) => {
        setCheckoutUrl(res?.cart?.checkoutUrl);
        if (res?.cart?.lines?.edges?.length) {
          setItemCount(res?.cart?.lines?.edges?.length);
          setIsEmptyCart(false);
          setCartTotalPrice(
            Number(res?.cart?.cost?.subtotalAmount.amount).toLocaleString(
              "en-US",
              {
                style: "currency",
                currency: "USD",
              }
            )
          );
          setCartList(
            res?.cart?.lines?.edges?.map((item) => ({
              lineId: item?.node.id,
              variantId: item?.node.merchandise.id,
              size: item?.node?.merchandise.title,
              image: item?.node?.merchandise.image.src,
              productName: item?.node?.merchandise?.product.title,
              qty: item?.node?.quantity,
              price: Number(
                item?.node?.merchandise?.price.amount
              )?.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              }),
            }))
          );
        } else {
          setIsEmptyCart(true);
        }
      });
    } else {
      createCart().then((res) =>
        setCookie("cartId", res?.cartCreate?.cart?.id, {
          withCredentials: true,
          httpOnly: true,
          secure: true,
          domain: "localhost",
          sameSite: "none",
        })
      );
    }
  }, [cartId, createCart, getCart, isCartCookie, setItemCount]);

  const onQtyClick = (type, lineId, qty) => {
    if (type === "plus") {
      updateCart(cartId, lineId, qty + 1).then((res) => {
        setCartTotalPrice(
          Number(
            res?.cartLinesUpdate?.cart?.cost?.subtotalAmount.amount
          )?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })
        );
        setItemCount(res?.cartLinesUpdate?.cart?.lines?.edges?.length);
        setCartList(
          res?.cartLinesUpdate?.cart?.lines?.edges?.map((item) => ({
            lineId: item?.node.id,
            variantId: item?.node.merchandise.id,
            size: item?.node?.merchandise.title,
            image: item?.node?.merchandise.image.src,
            productName: item?.node?.merchandise?.product.title,
            qty: item?.node?.quantity,
            price: Number(
              item?.node?.merchandise?.price.amount
            )?.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            }),
          }))
        );
      });
    }
    if (type === "minus") {
      if (qty - 1 === 0) {
        return;
      } else {
        updateCart(cartId, lineId, qty - 1).then((res) => {
          setCartTotalPrice(
            Number(
              res?.cartLinesUpdate?.cart?.cost?.subtotalAmount.amount
            ).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })
          );
          setItemCount(res?.cartLinesUpdate?.cart?.lines?.edges?.length);
          setCartList(
            res?.cartLinesUpdate?.cart?.lines?.edges?.map((item) => ({
              lineId: item?.node.id,
              variantId: item?.node.merchandise.id,
              size: item?.node?.merchandise.title,
              image: item?.node?.merchandise.image.src,
              productName: item?.node?.merchandise?.product.title,
              qty: item?.node?.quantity,
              price: Number(
                item?.node?.merchandise?.price.amount
              )?.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              }),
            }))
          );
        });
      }
    }
  };

  const onDeleteClick = (lineId) => {
    deleteCart(cartId, lineId).then((res) => {
      setItemCount(res?.cartLinesRemove?.cart?.lines?.edges?.length);
      if (res?.cartLinesRemove?.cart?.lines?.edges?.length) {
        setIsEmptyCart(false);

        setCartTotalPrice(
          Number(
            res?.cartLinesRemove?.cart?.cost?.subtotalAmount.amount
          ).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })
        );
        setCartList(
          res?.cartLinesRemove?.cart?.lines?.edges?.map((item) => ({
            lineId: item?.node.id,
            variantId: item?.node.merchandise.id,
            size: item?.node?.merchandise.title,
            image: item?.node?.merchandise.image.src,
            productName: item?.node?.merchandise?.product.title,
            qty: item?.node?.quantity,
            price: Number(
              item?.node?.merchandise?.price.amount
            )?.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            }),
          }))
        );
      } else {
        setIsEmptyCart(true);
      }
    });
  };
  const handleClickOutside = (event) => {
    if (cartRef.current && !cartRef.current.contains(event.target)) {
      setIsCart(false); // 외부 클릭 시 카트 닫기
      document.body.classList.remove("no-scroll");
    }
  };
  useEffect(() => {
    if (isCart) {
      document
        .getElementsByClassName(styles.container)[0]
        .classList.add(styles.open);
      document.body.classList.add("no-scroll");
    } else {
      document
        .getElementsByClassName(styles.container)[0]
        .classList.remove(styles.open);
      document.body.classList.remove("no-scroll");
    }
  }, [isCart]);
  useEffect(() => {
    if (isCart) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside); // 정리
    };
  }, [isCart]);
  const onLinkClick = (title, action, pathname) => {
    linkClickEvent(title, action, pathname);
  };
  return (
    <div className={styles.container} ref={cartRef}>
      <div className={styles.header}>
        <p>Cart</p>
        <CloseIcon onClick={onisCartClick} />
      </div>
      {!isEmptyCart ? (
        <section className={styles.cart_list_container}>
          <ul className={styles.product_list}>
            <SimpleBar className={styles.scroll_bar}>
              {cartList?.map((items, idx) => (
                <li key={idx}>
                  <div className={styles.product_info}>
                    <img src={items.image} alt="product_img" />
                    <div>
                      <h3>{items.productName}</h3>
                      <span>
                        <p>Size :&nbsp;</p>
                        <p>{items.size}</p>
                      </span>
                      <p>{items.price}</p>
                      <div className={styles.select_qty_delete}>
                        <div className={styles.select_qty}>
                          <MinusIcon
                            onClick={() =>
                              onQtyClick("minus", items.lineId, items.qty)
                            }
                          />
                          <p>{items.qty}</p>
                          <PlusIcon
                            onClick={() =>
                              onQtyClick("plus", items.lineId, items.qty)
                            }
                          />
                        </div>
                        <TrashCanIcon
                          onClick={() => onDeleteClick(items.lineId)}
                        />
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </SimpleBar>
          </ul>
          <section className={styles.subtotal}>
            <div>
              <h3>Subtotal :&nbsp;</h3>
              <p>{cartTotalPrice}</p>
            </div>
            <p>Shipping and taxes calculated at checkout</p>
          </section>
          <a
            href={customerCookie ? checkoutUrl : "/login"}
            className={styles.checkout}
            onClick={() =>
              onLinkClick(
                "Cart",
                customerCookie ? "To Checkout" : "To Login",
                location.pathname
              )
            }
          >
            Check out
          </a>
        </section>
      ) : (
        <section className={styles.cart_empty_container}>
          <p>Your cart is empty.</p>
          <section className={styles.cart_empty_gif}>
            <div />
            <div />
            <div />
          </section>
          <a
            className={styles.cart_empty_btn}
            href="/product/detail"
            onClick={() =>
              onLinkClick("Cart", "To Product Detail", location.pathname)
            }
          >
            Shop now
          </a>
        </section>
      )}
    </div>
  );
}
