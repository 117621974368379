import { graphqlRequest } from "../api/api";

export default class ProductService {
  async getProducts(count) {
    let data = JSON.stringify({
      query: `{ products(first: ${count}) {
                edges {
                  node {
                    id
                    title
                    description
                    images(first:1){
                        edges{
                            node{
                                src
                            }
                        }
                    }
                  }
                  
                }
              }
    }`,
      variables: count,
    });
    return await graphqlRequest(data);
  }
  async getProductDetail() {
    let data = JSON.stringify({
      query: `{
       product(id: "gid://shopify/Product/8799816351979") {
            id
            title
            productType
            description
            descriptionHtml
            metafield(namespace:"shopify",key:"fabric"){
              key               
              value
          }
            images(first: 10) {
              edges {
                node {
                  src
                  altText
                }
              }
            }
            variants(first: 10) {
              edges {
                node {
                  id
                  title
                  sku
                  quantityAvailable
                  price {
                    amount
                    currencyCode
                  }
                }
              }
            }
          }
    }`,
      variables: {},
    });
    try {
      let res = await graphqlRequest(data).then((res) => res);
      return res;
    } catch (e) {
      throw new Error(e);
    }
  }
  async getFabricList(ids) {
    let data = JSON?.stringify({
      query: `query GetMetaobjects($ids: [ID!]!) {
        nodes(ids: $ids) {
          ... on Metaobject {
            id
            fields {
              key
              value
            }
          }
        }
      }`,
      variables: { ids: ids ? JSON?.parse(ids) : [] },
    });
    return await graphqlRequest(data);
  }
}
