import React, {
  createContext,
  createRef,
  useCallback,
  useContext,
  useMemo,
} from "react";

const OrderListContext = createContext({});
const contextRef = createRef();
export function OrderListProvider({ orderListService, children }) {
  const orderList = useCallback(
    async (first) =>
      await orderListService
        .orderList(first)
        .then((res) => res)
        .catch((e) => e),
    [orderListService]
  );
  const orderDetail = useCallback(
    async (orderNo) => await orderListService.orderDetail(orderNo),
    [orderListService]
  );
  const orderDetailFulfillment = useCallback(
    async (orderNo) =>
      await orderListService
        .orderDetailFulfillment(orderNo)
        .then((res) => res)
        .catch((e) => e),
    [orderListService]
  );
  const orderCancel = useCallback(
    async (orderId) =>
      await orderListService
        .orderCancel(orderId)
        .then((res) => res)
        .catch((e) => e),
    [orderListService]
  );
  const context = useMemo(
    () => ({ orderList, orderDetail, orderDetailFulfillment, orderCancel }),
    [orderList, orderDetail, orderDetailFulfillment, orderCancel]
  );
  return (
    <OrderListContext.Provider value={context}>
      {children}
    </OrderListContext.Provider>
  );
}

export default OrderListContext;
export const fetchToken = () => contextRef.current;
export const useOrderList = () => useContext(OrderListContext);
