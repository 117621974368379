import React from "react";
import styles from "./ProductDetail.module.scss";

import { ReactComponent as QuestionCircle } from "../img/qustion_mark_circle.svg";
import { ReactComponent as BottomArrow } from "../img/bottom_arrow.svg";
import { ReactComponent as Star } from "../img/8a_star.svg";
import ProductDetailImages from "../components/ProductDetailImages";
import ProductDetailInfo from "../components/ProductDetailInfo";
import ProductDetailSize from "../components/ProductDetailSize";
import AddToCart from "../components/AddToCart";
import ProductDetailDecscription from "../components/ProductDetailDecscription";
import { useProduct } from "../context/ProductContext";
import TotalPrice from "../components/TotalPrice";
import useWindowSize from "../custom/useWindowSize";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { pageView } from "../api/ga4";
export default function ProductDetail() {
  const { detailInfo, detailImages } = useProduct();
  const location = useLocation();
  useEffect(() => {
    pageView("Product Detail", location.pathname);
  }, [location]);
  const { width } = useWindowSize();
  return (
    <section className={styles.container}>
      <section className={styles.banner}>
        <Star />
        <p>Free shipping on orders over $100!</p>
        <Star />
      </section>
      <div className={styles.inner}>
        <ProductDetailImages />
        <div>
          <section className={styles.detail_info}>
            <div className={styles.detail_info_inner}>
              <ProductDetailInfo />
              <ProductDetailSize />
              <TotalPrice />
              <div className={styles.tax_info}>
                <p>Tax not included.</p>
                <QuestionCircle className={styles.hover_icon} />
                <div className={styles.hover_info}>
                  <p>
                    mondayoff shop is not liable for any additional taxes, fees,
                    logistics, or other charges imposed by your local government
                    or delivery service for receiving your package.
                  </p>
                  <BottomArrow />
                </div>
              </div>
              <AddToCart />
            </div>
          </section>
          <section className={styles.detail_description}>
            <div className={styles.detail_description_inner}>
              <ProductDetailDecscription productInfo={detailInfo} />
            </div>
          </section>
        </div>
        {width < 1440 && (
          <section className={styles.bottom_img}>
            {detailImages?.slice(1).map((items) => (
              <img src={items.node?.src} alt="" key={items.node?.src} />
            ))}
          </section>
        )}
      </div>
    </section>
  );
}
