import React from "react";
import { useEffect, useRef } from "react";
import { useState } from "react";
import { getCookie, removeCookie, setCookie } from "../cookie/cookie";
import { ReactComponent as EyeIcon } from "../img/eye-open.svg";
import { ReactComponent as EyeCloseIcon } from "../img/eye-close.svg";
import { useCustomer } from "../context/CustomerContext";

import { ReactComponent as NotFoundIcon } from "../img/notFoundIcon.svg";
import { ReactComponent as ExsistIcon } from "../img/exsistIcon.svg";
import LoadingSpinner from "../common/LoadingSpinner";
import styles from "./EditProfile.module.scss";
import { useNavigate } from "react-router-dom";
export default function EditProfile() {
  const { getCustomer, checkGameId, addMetafields, updateCustomer } =
    useCustomer();
  const navigate = useNavigate();
  const token = getCookie("access-token");
  useEffect(() => {
    !token && navigate("/login");
  }, [navigate, token]);
  const [isUpdateClick, setIsUpdateClick] = useState(false);
  const [isPasswordViewClick, setIsPasswordViewClick] = useState(false);
  const [isCheckGameIdClick, setIsCheckGameIdClick] = useState(false);

  const [customerInfo, setCustomerInfo] = useState({});
  const [isGameNickName, setisGameNickName] = useState({});
  const [formsData, setFormsData] = useState({
    passwd: "",
    gameId: "",
  });
  const passwdRef = useRef(null);
  const gameIdRef = useRef(null);
  useEffect(() => {
    getCustomer().then((res) => {
      setCustomerInfo(res?.customer);
      setFormsData((prev) => ({
        ...prev,
        gameId: res?.customer?.metafields[0]?.value,
        email: res?.customer?.email,
      }));
    });
  }, []);
  useEffect(() => {
    if (isCheckGameIdClick && formsData?.gameId?.length) {
      checkGameId(formsData?.gameId).then((res) => {
        if (isCheckGameIdClick) {
          setisGameNickName(res);
          document.body.classList.add("no-scroll");
        }
      });
    }
  }, [checkGameId, formsData.gameId, isCheckGameIdClick]);

  useEffect(() => {
    const passwdLabel = document.getElementsByClassName(styles.passwd_label);
    const gameIdLabel = document.getElementsByClassName(styles.gameId_label);
    passwdRef.current.onfocus = function (e) {
      passwdLabel[0].style.border = "1px solid var(--gray-95, #2e2e2e)";
    };
    passwdRef.current.onblur = function (e) {
      passwdLabel[0].style.border = "1px solid var(--gray-10)";
    };
    gameIdRef.current.onfocus = function (e) {
      gameIdLabel[0].style.border = "1px solid var(--gray-95, #2e2e2e)";
    };
    gameIdRef.current.onblur = function (e) {
      gameIdLabel[0].style.border = "1px solid var(--gray-10)";
    };
  }, []);
  const onChange = (e) => {
    const { value, name } = e.target;
    switch (name) {
      case "email":
        setFormsData({ ...formsData, email: customerInfo?.email });
        return;
      case "password":
        setFormsData({ ...formsData, passwd: value });
        return;
      case "gameId":
        setFormsData({ ...formsData, gameId: value });
        return;
      default:
        return setFormsData({});
    }
  };
  const onPasswordViewClick = () => {
    setIsPasswordViewClick(!isPasswordViewClick);
  };
  const onCheckIdClick = () => {
    if (formsData.gameId.length) {
      setIsCheckGameIdClick(true);
    }
  };
  const onCheckGameIdConfirmClick = () => {
    setIsCheckGameIdClick(false);
    document.body.classList.remove("no-scroll");
  };
  const onCheckGameIdCancelClick = () => {
    setIsCheckGameIdClick(false);
    document.body.classList.remove("no-scroll");
  };
  const onUpdateClick = () => {
    setIsUpdateClick(true);
    if (!formsData?.passwd.length) {
      setIsUpdateClick(false);
      alert("Check your Password");
    } else {
      updateCustomer(formsData).then((res) => {
        removeCookie("access-token");
        setCookie(
          "access-token",
          res?.customerUpdate?.customerAccessToken?.accessToken,
          {
            httpOnly: false,
            secure: true,
            sameSite: "None",
            expires: new Date(
              res?.customerUpdate?.customerAccessToken?.expiresAt
            ),
          }
        );
        formsData.gameId.length
          ? addMetafields(
              res?.customerUpdate?.customer?.id,
              formsData?.gameId
            ).then((res) => window.location.reload())
          : window.location.reload();
      });
    }
  };
  return (
    <div className={styles.wrap}>
      {isCheckGameIdClick && (
        <>
          <div className={`${styles.overlay} ${styles.active}`}>
            <div className={styles.modal_container}>
              <section className={styles.top_section}>
                {isGameNickName?.exists ? <ExsistIcon /> : <NotFoundIcon />}

                <div>
                  <h3>
                    {isGameNickName?.exists
                      ? "Verify Game ID"
                      : "The Game ID Does not Exist"}
                  </h3>
                  {isGameNickName?.exists ? (
                    <p>
                      Is <strong>{isGameNickName?.useName}</strong> the nickname
                      you use in Dino: Mutant?
                    </p>
                  ) : (
                    <p>Please check your Game ID.</p>
                  )}
                </div>
              </section>
              <section className={styles.btn_container}>
                <button onClick={onCheckGameIdCancelClick}>Cancel</button>
                <button onClick={onCheckGameIdConfirmClick}>Confirm</button>
              </section>
            </div>
          </div>
        </>
      )}
      <div className={styles.container}>
        <h2>Edit Profile</h2>
        <section className={styles.forms_container}>
          <div className={styles.forms}>
            <p className={styles.forms_title}>First name</p>
            <p className={styles.forms_value}>{customerInfo?.firstName}</p>
          </div>
          <div className={styles.forms}>
            <p className={styles.forms_title}>Last name</p>
            <p className={styles.forms_value}>{customerInfo?.lastName}</p>
          </div>
          <div className={styles.forms}>
            <p className={styles.forms_title}>Email</p>
            <p className={styles.forms_value}>{customerInfo?.email}</p>
          </div>
          <div className={styles.forms_passwd}>
            <p className={styles.forms_title}>Password</p>
            <label className={styles.passwd_label}>
              <input
                type={isPasswordViewClick ? "text" : "password"}
                name="password"
                ref={passwdRef}
                onChange={onChange}
              />
              {isPasswordViewClick ? (
                <EyeCloseIcon onClick={onPasswordViewClick} />
              ) : (
                <EyeIcon onClick={onPasswordViewClick} />
              )}
            </label>
          </div>
          <div className={styles.forms_gameId}>
            <p className={styles.forms_title}>Game ID(Option)</p>

            <label className={styles.gameId_label}>
              <input
                type="text"
                name="gameId"
                ref={gameIdRef}
                onChange={onChange}
                value={formsData.gameId}
              />
              <button
                className={styles.gameId_check_btn}
                onClick={onCheckIdClick}
              >
                Check
              </button>
            </label>
          </div>
        </section>

        <div
          className={styles.signup_Btn}
          onClick={!isUpdateClick && onUpdateClick}
        >
          {isUpdateClick ? <LoadingSpinner /> : "Edit"}
        </div>
      </div>
    </div>
  );
}
