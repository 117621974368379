import React from "react";
import styles from "./FAQ.module.scss";
import FAQList from "../components/FAQList";
import { order, shipping } from "../data/FAQList";
import { Link } from "react-router-dom";
export default function FAQ() {
  return (
    <section className={styles.container}>
      <div className={styles.wrap}>
        <h2 className={styles.title}>FAQ</h2>
        <section className={styles.lists}>
          <FAQList data={order} title="Order" />
          <FAQList data={shipping} title="Shipping" />
          <div className={styles.contact_btn}>
            <p>Need something else?</p>
            <Link to="/contact">Contact</Link>
          </div>
        </section>
      </div>
    </section>
  );
}
