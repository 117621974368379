import { useState, useEffect } from "react";
import { debounce } from "lodash";
export default function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  useEffect(() => {
    // Resize 이벤트 핸들러
    const handleResize = debounce(() => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }, 50);

    // 이벤트 리스너 등록
    window.addEventListener("resize", handleResize);

    // 초기 실행 (렌더 직후)
    handleResize();

    // 컴포넌트 언마운트 시 이벤트 리스너 제거
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowSize;
}
