import React, { useEffect } from "react";
import { useLocation } from "react-router";
import { Link, useNavigate } from "react-router-dom";
import { useOrderList } from "../context/OrderListContext";
import { ReactComponent as ArrowIcon } from "../img/left_arrow.svg";
import { ReactComponent as InfoIcon } from "../img/Information_in_Circle.svg";
import styles from "./OrderDetail.module.scss";
import moment from "moment";
import OrderDetailItem from "../components/OrderDetailItem";
import { useCustomer } from "../context/CustomerContext";
import { useState } from "react";
import { getCookie } from "../cookie/cookie";
export default function OrderDetail() {
  const { state } = useLocation();

  const { orderDetailFulfillment, orderCancel } = useOrderList();
  const { customerPayment } = useCustomer();
  const [paymentInfo, setPaymentInfo] = useState({});
  const [fulfillment, setFulfillment] = useState({});
  const billingAddress = state?.data?.billingAddress;
  const shippingAddress = state?.data?.shippingAddress;
  const itemList = state?.data?.lineItems?.edges;
  const token = getCookie("access-token");
  const navigate = useNavigate();
  useEffect(() => {
    !token && navigate("/login");
  }, [navigate, token]);
  useEffect(() => {
    if (token) {
      const orderId = state?.orderId;
      const match = orderId?.match(/Order\/(\d+)/);
      const orderNo = match ? match[1] : null;
      customerPayment(orderId).then((res) => setPaymentInfo(res));

      orderDetailFulfillment(orderNo).then((res) => {
        setFulfillment(res?.fulfillments[0]);
      });
    } else {
      return;
    }
  }, []);

  const onCancelClick = () => {
    const orderId = state?.orderId;
    orderCancel(orderId).then((res) =>
      res?.data?.orderCancel.job !== null && !res?.data?.orderCancel?.job.done
        ? navigate(-1)
        : alert(res?.data?.orderCancel?.userErrors[0]?.message)
    );
  };

  return (
    <div className={styles.wrap}>
      <section className={styles.container}>
        <Link to="/mypage" className={styles.back_btn}>
          <ArrowIcon />
          <p>List</p>
        </Link>
        <section className={styles.payment_info}>
          <div className={styles.payment_info_top}>
            <span>
              <h2>Order Details</h2>
              <p>{state?.data?.fulfillmentStatus}</p>
              <InfoIcon />
            </span>
            {!fulfillment ? (
              <button className={styles.cancel_btn} onClick={onCancelClick}>
                Cancel
              </button>
            ) : (
              ""
            )}
          </div>
          <div className={styles.order_info}>
            <div className={styles.order_info_box}>
              <p>Orders</p>
              <p>{state?.data?.name}</p>
            </div>
            <div className={styles.order_info_box}>
              <p>Date</p>
              <p>
                {moment(state?.data?.processedAt).format(
                  "MMM DD, YYYY, hh:mm:ss"
                )}
              </p>
            </div>
            <div className={styles.order_info_box}>
              <p>Track Package</p>
              {fulfillment?.tracking_number ? (
                <a href={fulfillment?.tracking_url}>
                  {fulfillment?.tracking_number}
                </a>
              ) : (
                <p>n/a</p>
              )}
            </div>
            {/*Payment*/}
            <div className={styles.payment_container}>
              <h2>Payment Information</h2>
              {paymentInfo?.order?.transactions?.map((data) => (
                <>
                  <div>
                    <p>Payment Method</p>
                    <p>{data?.accountNumber}</p>
                  </div>
                  <div>
                    <p>Date of Processing</p>
                    <p>
                      {moment(data?.processedAt).format(
                        "MMM DD, YYYY, hh:mm:ss"
                      )}
                    </p>
                  </div>
                </>
              ))}
            </div>
            {/*address*/}
            <div className={styles.address_info}>
              <div>
                <h2>Shipping Address</h2>
                <div className={styles.address_info_box}>
                  <div className={styles.address_each_info}>
                    <p>Name</p>
                    <p>
                      {shippingAddress?.firstName} {shippingAddress?.lastName}
                    </p>
                  </div>
                  <div className={styles.address_each_info}>
                    <p>Address</p>
                    <div>
                      <p>{shippingAddress?.address1}</p>
                      <p>
                        {shippingAddress?.city},&nbsp;
                        {shippingAddress?.province}
                        ,&nbsp;
                        {shippingAddress?.zip}
                      </p>
                      <p>{shippingAddress?.country}</p>
                    </div>
                  </div>
                  <div className={styles.address_each_info}>
                    <p>Contact</p>
                    {shippingAddress?.phone ? shippingAddress?.phone : "n/a"}
                  </div>
                </div>
              </div>
              <div>
                <h2>Billing Address</h2>
                <div className={styles.address_info_box}>
                  <div className={styles.address_each_info}>
                    <p>Name</p>
                    <p>
                      {billingAddress?.firstName} {billingAddress?.lastName}
                    </p>
                  </div>
                  <div className={styles.address_each_info}>
                    <p>Address</p>
                    <div>
                      <p>{billingAddress?.address1}</p>
                      <p>
                        {billingAddress?.city},&nbsp;{billingAddress?.province}
                        ,&nbsp;
                        {billingAddress?.zip}
                      </p>
                      <p>{billingAddress?.country}</p>
                    </div>
                  </div>
                  <div className={styles.address_each_info}>
                    <p>Contact</p>
                    <p>
                      {billingAddress?.phone ? billingAddress?.phone : "n/a"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className={styles.order_detail}>
          <h3>Order Items</h3>
          <ul className={styles.order_detail_items}>
            {itemList?.map((item) => (
              <OrderDetailItem item={item} />
            ))}
          </ul>
        </section>
        <section className={styles.total_price}>
          <h2>Order Total</h2>
          <div className={styles.prize_item}>
            <div>
              <p>Subtotal</p>
              <p>
                {Number(state?.data?.subtotalPrice?.amount).toLocaleString(
                  "en-US",
                  {
                    style: "currency",
                    currency: "USD",
                  }
                )}
              </p>
            </div>
            <div>
              <p>Shipping</p>
              <p>
                {Number(state?.data?.totalShippingPrice.amount).toLocaleString(
                  "en-US",
                  {
                    style: "currency",
                    currency: "USD",
                  }
                )}
              </p>
            </div>
            <div>
              <p>Taxes</p>
              <p>
                {Number(state?.data?.totalTax.amount).toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </p>
            </div>
          </div>
          <div className={styles.item_total_price}>
            <p>Total</p>
            <p>
              {Number(state?.data?.totalPrice.amount).toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </p>
          </div>
        </section>
      </section>
    </div>
  );
}
