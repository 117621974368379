import React from "react";
import styles from "./OrderDetailItem.module.scss";
import { ReactComponent as LineIcon } from "../img/diagonal.svg";
import { getCookie } from "../cookie/cookie";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
export default function OrderDetailItem({ item }) {
  const token = getCookie("access-token");
  const navigate = useNavigate();
  useEffect(() => {
    !token.length && navigate("/login");
  }, [navigate, token.length]);
  return (
    <li className={styles.container}>
      <img src={item?.node?.variant?.image?.url} alt="" />
      <div className={styles.product_info}>
        <div className={styles.order_item}>
          <p className={styles.order_item_name}>{item?.node?.title}</p>
          <span className={styles.selectd_size_qty}>
            <p>{item?.node?.variant?.title}</p>
            <LineIcon />
            <p>Qty : {item?.node?.quantity}</p>
          </span>
        </div>
        <p className={styles.order_item_price}>
          Price&nbsp;:&nbsp;
          {Number(item?.node?.originalTotalPrice?.amount).toLocaleString(
            "en-US",
            {
              style: "currency",
              currency: "USD",
            }
          )}
        </p>
      </div>
    </li>
  );
}
