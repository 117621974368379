import axios from "axios";

export const graphqlRequest = async (data) => {
  const config = {
    method: "POST",
    url: process.env.REACT_APP_STOREFRONT_API_BASE_URL,
    headers: {
      "Content-Type": "application/json",
      "X-Shopify-Storefront-Access-Token":
        process.env.REACT_APP_SHOP_STORE_TOKEN,
    },
    data: data,
  };

  try {
    const response = await axios(config);
    if (response.data.errors) {
      throw new Error(JSON.stringify(response.data.errors));
    }
    return response.data.data;
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
};
export const adminGraphqlRequest = async (data) => {
  const config = {
    method: "POST",
    url: process.env.REACT_APP_ADMIN_API_BASE_URL,
    headers: {
      "Content-Type": "application/json",
      "X-Shopify-Access-Token": process.env.REACT_APP_SHOP_ADMIN_TOKEN,
    },
    data: data,
  };

  try {
    const response = await axios(config);
    if (response.data.errors) {
      throw new Error(JSON.stringify(response.data.errors));
    }
    return response.data.data;
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
};
