import React from "react";
import styles from "./OrderHistory.module.scss";
import OrderItem from "./OrderItem";
import moment from "moment";
import { Link } from "react-router-dom";
import { ReactComponent as DotLineIcon } from "../img/orderhistory_line.svg";
export default function OrderHistory({ item }) {
  const date = moment(item?.node?.processedAt).format("MMM. DD. YYYY");

  return (
    <li className={styles.container}>
      <section className={styles.top_section}>
        <p className={styles.status}>{item?.node?.financialStatus}</p>
        <span>
          <p>{date}</p>
          <Link
            to="/mypage/detail"
            state={{ orderId: item?.node?.id, data: item?.node }}
          >
            View Detail
          </Link>
        </span>
        <p className={styles.order_id}>{item?.node?.name}</p>
        <DotLineIcon />
      </section>
      {item?.node?.lineItems?.edges.map((product) => (
        <OrderItem product={product} />
      ))}
      <div className={styles.total_prize_container}>
        <DotLineIcon />
        <div>
          <p>total:</p>
          <p>
            {Number(item?.node?.totalPrice.amount).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
          </p>
        </div>
      </div>
    </li>
  );
}
