import React from "react";
import { Link } from "react-router-dom";
import styles from "./SubmissionEmail.module.scss";
export default function SubmissionEmail() {
  return (
    <section className={styles.container}>
      <div className={styles.wrap}>
        <h2>Your Message is in Our Inbox!</h2>
        <p>
          Thanks! We got your message and will check it out. We'll shoot you an
          email with our reply!
        </p>
        <Link to="/" className={styles.home_btn}>
          Back to Home
        </Link>
      </div>
    </section>
  );
}
