import React from "react";
import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { clickEvent, pageView } from "../api/ga4";
import { useCustomer } from "../context/CustomerContext";
import { getCookie } from "../cookie/cookie";
import styles from "./SendResetPasswd.module.scss";
export default function SendResetPasswd() {
  const { resetPasswd } = useCustomer();
  const location = useLocation();
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const token = getCookie("access-token");
  useEffect(() => {
    pageView("Find Password", location.pathname);
  }, [location]);
  useEffect(() => {
    token && navigate("/");
  }, [navigate, token]);
  const regEmail =
    /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;

  const onEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const onClick = () => {
    if (regEmail.test(email)) {
      clickEvent("send-btn", "Send Email", location.pathname);
      resetPasswd(email).then((res) =>
        navigate("/passwd/reset/submit", { state: { email } })
      );
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.inner}>
        <h2>Forgot Password?</h2>
        <p>
          Enter your email, and we’ll send you a link to reset your password.
        </p>
        <div className={styles.setpasswd}>
          <p>Email</p>
          <input type="email" onChange={onEmailChange} />
        </div>

        <div className={styles.setpasswd_btn_box}>
          <button onClick={onClick} className={styles.setpasswd_btn_submission}>
            Reset password
          </button>
          <Link
            to="/login"
            onClick={() =>
              clickEvent("back-btn", "To login", location.pathname)
            }
            className={styles.setpasswd_btn_cancel}
          >
            Back to Login
          </Link>
        </div>
      </div>
    </div>
  );
}
