import React, { useEffect, useState, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { clickEvent } from "../api/ga4";
import { useCart } from "../context/CartContext";
import { useCustomer } from "../context/CustomerContext";
import { useProduct } from "../context/ProductContext";
import { getCookie, removeCookie, setCookie } from "../cookie/cookie";
import styles from "./AddToCart.module.scss";
import moment from "moment";
export default function AddToCart() {
  const { selectSize } = useProduct();
  const cartId = getCookie("cartId");
  const navigate = useNavigate();
  const location = useLocation();
  const [varitantsIds, setVaritantsIds] = useState([]);
  const [nullCheck, setNullCheck] = useState(null);
  const { isCookie } = useCustomer();
  const [isCartCookie, setIsCartCookie] = useState(false);

  useEffect(() => {
    setIsCartCookie(!!cartId);
  }, [cartId, location]);

  const { createCart, getCart, addCart, setIsCart, isCart } = useCart();

  useEffect(() => {
    if (isCartCookie) {
      getCart(cartId).then((res) => setNullCheck(res.cart));
      if (!nullCheck) {
        removeCookie("cartId");
        createCart().then((res) =>
          setCookie("cartId", res?.cartCreate?.cart?.id, {
            withCredentials: true,
            httpOnly: false,
            path: "/",
            secure: true,
            sameSite: "None",
          })
        );
      } else {
        getCart(cartId).then((res) =>
          setVaritantsIds(
            res?.cart?.lines?.edges?.map((items) => items.node.merchandise.id)
          )
        );
      }
    }
  }, [createCart, getCart, location]);

  const onAddCartClick = useCallback(async () => {
    if (isCookie) {
      if (selectSize.id) {
        if (isCartCookie) {
          clickEvent("Size", selectSize.size, location.pathname);
          await addCart(cartId, selectSize.id, selectSize.qty)
            .then((res) => res)
            .then(() => setIsCart(true));
        } else {
          clickEvent("Size", selectSize.size, location.pathname);
          await createCart().then((res) =>
            setCookie("cartId", res?.cartCreate?.cart?.id, {
              withCredentials: true,
              httpOnly: false,
              path: "/",
              secure: true,
              sameSite: "None",
            }).then(() => addCart(cartId, selectSize.id, selectSize.qty))
          );
        }
      }
    } else {
      navigate("/login");
    }
  }, [
    addCart,
    createCart,
    isCookie,
    navigate,
    selectSize.id,
    selectSize.qty,
    setIsCart,
  ]);

  return moment().isAfter("2024-12-26 18:00:00") ? (
    <div className={styles.soldout}>Add to cart</div>
  ) : (
    <button className={styles.btn} onClick={onAddCartClick}>
      Add to cart
    </button>
  );
}
