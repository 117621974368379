import { graphqlRequest } from "../api/api";

export default class CartService {
  async createCart() {
    let data = JSON.stringify({
      query: `mutation {
        cartCreate {
          cart {
            id
            lines(first: 10) {
              edges {
                node {
                  id
                  merchandise {
                    ... on ProductVariant {
                      id
                      title
                    }
                  }
                  quantity
                }
              }
            }
          }
        }
      }`,
    });
    return await graphqlRequest(data);
  }
  async getCart(cartId) {
    let data = JSON.stringify({
      query: ` query {
            cart(id: "${cartId}") {
              id
              checkoutUrl
              cost{
                subtotalAmount{
                    amount
                }
              }
              lines(first: 10) {
                edges {
                  node {
                    id
                    merchandise {
                      ... on ProductVariant {
                        id
                        title
                        price {
                            amount
                            currencyCode
                          }
                        image{
                            src
                        }
                        product{
                            title
                        }
                      }
                    }
                    quantity
                  }
                }
                
              }
            }
           
          }`,
    });

    return await graphqlRequest(data);
  }
  async addCart(cartId, varitantId, qty) {
    let data = JSON.stringify({
      query: `mutation {
            cartLinesAdd(cartId: "${cartId}", lines: [{ merchandiseId: "${varitantId}", quantity: ${qty} }]) {
              cart {
                id
                checkoutUrl
                lines(first: 10) {
                  edges {
                    node {
                      id
                      merchandise {
                        ... on ProductVariant {
                          id
                          title
                        }
                      }
                      quantity
                    }
                  }
                }
              }
            }
          }`,
    });

    return await graphqlRequest(data);
  }
  async updateCart(cartId, lineId, qty) {
    let data = JSON.stringify({
      query: `mutation updateCartLine($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
        cartLinesUpdate(cartId: $cartId, lines: $lines) {
          cart {
            id
            checkoutUrl
            cost{
                subtotalAmount{
                    amount
                }
            }
            lines(first: 10) {
              edges {
                node {
                  id
                  quantity
                  merchandise {
                    ... on ProductVariant {
                      id
                      title
                      price{
                            amount
                        }
                        image{
                            src
                        }
                        product{
                            title
                            
                        }
                    }
                  }
                }
              }
            }
          }
        }
      }`,
      variables: {
        cartId,
        lines: [{ id: lineId, quantity: qty }],
      },
    });
    return await graphqlRequest(data);
  }
  async deleteCart(cartId, lineId) {
    let data = JSON.stringify({
      query: `mutation CartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
        cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
          cart {
            id
            checkoutUrl
            cost {
              subtotalAmount {
                amount
              }
            }
            lines(first: 10) {
              edges {
                node {
                  id
                  quantity
                  merchandise {
                    ... on ProductVariant {
                      id
                      title
                      price {
                        amount
                      }
                      image {
                        src
                      }
                      product {
                        title
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }`,
      variables: {
        cartId,
        lineIds: [lineId],
      },
    });

    return await graphqlRequest(data);
  }
}
