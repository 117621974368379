import React, { useState } from "react";
import styles from "./ContactUs.module.scss";
import subjectList from "../data/contactUsSubject";
import { ReactComponent as Star } from "../img/input_star.svg";
import { ReactComponent as DropdownArrow } from "../img/dropdown_arrow.svg";
import { useCustomer } from "../context/CustomerContext";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../common/LoadingSpinner";
export default function ContactUs() {
  const { sendContactMail } = useCustomer();
  const navigate = useNavigate();
  const [isSelected, setIsSelected] = useState({ id: 1, title: "Order" });
  const [isDropdownClick, setIsDropdownClick] = useState(false);
  const [isSendMailClick, setIsSendMailClick] = useState(false);
  const [isNotEmailValidCheck, setIsNotEmailValidCheck] = useState(false);
  const [emailData, setEmailData] = useState({
    name: "",
    email: "",
    subject: isSelected.title,
    message: "",
  });
  const regEmail =
    /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
  const onEmailDataChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "name":
        setEmailData((prev) => ({ ...prev, name: value }));
        return;
      case "email":
        setIsNotEmailValidCheck(false);
        setEmailData((prev) => ({ ...prev, email: value }));
        return;
      case "message":
        setEmailData((prev) => ({ ...prev, message: value }));
        return;
      default:
        setEmailData({ name: "", email: "", subject: "", message: "" });
    }
  };
  const sendEmailClick = () => {
    setIsSendMailClick(true);
    if (!regEmail.test(emailData.email)) {
      setIsSendMailClick(false);
      setIsNotEmailValidCheck(true);
    } else {
      sendContactMail(emailData)
        .then((res) => navigate("/contact/submission"))
        .catch((e) => e);
    }
  };
  return (
    <section className={styles.container}>
      <div className={styles.wrap}>
        <div className={styles.title}>
          <h2>Contact us</h2>
          <p>
            Before you hit the submit button, please take a look at our FAQs! If
            you still have questions, feel free to fill out the form below or
            email us at <a href="mailto:shop@mondayoff.me">shop@mondayoff.me</a>
            !
          </p>
        </div>
        <section className={styles.contact_inputs}>
          <div className={styles.name}>
            <div className={styles.name_label}>
              <p>Name</p>
              <Star />
            </div>
            <input name="name" type="text" onChange={onEmailDataChange} />
          </div>
          <div
            className={`${styles.email} ${
              isNotEmailValidCheck && styles.invalid
            }`}
          >
            <div className={styles.email_label}>
              <p>Email</p>
              <Star />
            </div>
            <input name="email" type="email" onChange={onEmailDataChange} />
          </div>
          <div className={styles.subject}>
            <div className={styles.subject_label}>
              <p>Subject</p>
              <Star />
            </div>
            <div className={styles.subject_input}>
              <div
                className={styles.selected_subject}
                onClick={() => setIsDropdownClick((prev) => !prev)}
              >
                <p>{isSelected.title}</p>
                <DropdownArrow />
              </div>
              <ul
                className={`${styles.dropdown} ${
                  isDropdownClick ? styles.active : styles.inactive
                }`}
              >
                {subjectList.map((list) => (
                  <li
                    onClick={() => {
                      setIsSelected(list);
                      setIsDropdownClick(false);
                      setEmailData((prev) => ({
                        ...prev,
                        subject: list.title,
                      }));
                    }}
                  >
                    {list.title}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className={styles.message}>
            <div className={styles.message_label}>
              <p>Message</p>
              <Star />
            </div>
            <textarea name="message" onChange={onEmailDataChange} />
          </div>
        </section>
        <button
          className={styles.submit_btn}
          onClick={isSendMailClick ? "" : sendEmailClick}
        >
          {isSendMailClick ? <LoadingSpinner /> : "Submit"}
        </button>
      </div>
    </section>
  );
}
