import React from "react";
import { Link, useLocation } from "react-router-dom";
import styles from "./Footer.module.scss";
import logo from "../img/mondayoff_new_logo.png";
import dinoCharactor from "../img/dino_charactor.png";
import dinoBubble from "../img/dino_bubble.png";
import { ReactComponent as ArrowIcon } from "../img/arrow.svg";
import { ReactComponent as InstaIcon } from "../img/insta.svg";
import { ReactComponent as LinkedIcon } from "../img/linked.svg";
import { ReactComponent as YoutubeIcon } from "../img/youtube.svg";
import { ReactComponent as AppleIcon } from "../img/apple.svg";
import { ReactComponent as GoogleIcon } from "../img/google.svg";
import { ReactComponent as EmailIcon } from "../img/Email.svg";
import { ReactComponent as DotIcon } from "../img/dot_m.svg";
import { linkClickEvent } from "../api/ga4";

export default function Footer() {
  const location = useLocation();
  const onLinkClick = (urlName, action) => {
    linkClickEvent(urlName, action, location.pathname);
  };
  return (
    <div className={styles.container}>
      <span className={styles.footer_info}>
        <img className={styles.footer_logo} src={logo} alt="" />
        <div className={styles.footer_menu}>
          {/* <Link to="" className={styles.all_products}>
            <p>All Product</p>
            <ArrowIcon />
          </Link> */}
          <Link to="/faq">
            <p>FAQ</p>
            <ArrowIcon />
          </Link>
        </div>
        <div className={styles.footer_link}>
          <Link
            to="https://www.instagram.com/mondayoff_official/"
            target="_blank"
            onClick={() => onLinkClick("Footer", "To insta")}
          >
            <InstaIcon />
          </Link>
          <Link
            to="https://www.linkedin.com/company/mondayoff-inc/mycompany/"
            target="_blank"
            onClick={() => onLinkClick("Footer", "To linkedIn")}
          >
            <LinkedIcon />
          </Link>
          <Link
            to="https://www.youtube.com/channel/UCgYNSY4mEvtTjGUG7L56k6w"
            target="_blank"
            onClick={() => onLinkClick("Footer", "To Youtube")}
          >
            <YoutubeIcon />
          </Link>
          <Link
            to="https://apps.apple.com/us/developer/mondayoff/id1448226898"
            target="_blank"
            onClick={() => onLinkClick("Footer", "To apple store")}
          >
            <AppleIcon />
          </Link>
          <Link
            to="https://play.google.com/store/apps/developer?id=MondayOFF&hl=ko&gl=US"
            target="_blank"
            onClick={() => onLinkClick("Footer", "To google store")}
          >
            <GoogleIcon />
          </Link>
        </div>
        <div className={styles.footer_company_info}>
          <div className={styles.contact_time}>
            <p>We're here for you!</p>
            <p>Our working hours are 10 AM – 5 PM KST.</p>
          </div>
          <div className={styles.contact_email}>
            <EmailIcon />
            <a
              href="mailto://help-shop@mondayoff.me"
              target="_blank"
              rel="noreferrer"
              onClick={() => onLinkClick("Footer", "click contact")}
            >
              help-shop@mondayoff.me
            </a>
          </div>

          <div className={styles.footer_privacy_terms}>
            <p>©2024 MondayOFF</p>
            <DotIcon />
            <a href="https://mondayoffshop.com/policies/privacy-policy">
              Privacy Policy
            </a>
            <DotIcon />
            <a href="https://mondayoffshop.com/policies/refund-policy">
              Refund Policy
            </a>
            <DotIcon />
            <a href="https://mondayoffshop.com/policies/terms-of-service">
              Terms of Service
            </a>
            <DotIcon />
            <a href="https://mondayoffshop.com/policies/shipping-policy">
              Shipping Policy
            </a>
          </div>
        </div>
      </span>
      <img className={styles.footer_charactor} src={dinoCharactor} alt="" />
      <img className={styles.dino_bubble} src={dinoBubble} alt="" />
    </div>
  );
}
