import { graphqlRequest } from "../api/api";

import axios from "axios";
import { getCookie } from "../cookie/cookie";
const token = getCookie("access-token");
export default class CustomerService {
  async createCustomer(customerData) {
    let data = JSON.stringify({
      query: `mutation CreateCustomerWithMetafield {
                customerCreate(input: {
                  email: "${customerData.email}",
                  password: "${customerData.passwd}",
                  firstName: "${customerData.firstName}",
                  lastName: "${customerData.lastName}"
                }) {
                  customer {
                    id
                  }
                  customerUserErrors {
                    code
                    field
                    message
                  }
                }
              }`,
    });
    return await graphqlRequest(data);
  }

  async updateCustomer(customerData) {
    let data = JSON.stringify({
      query: `mutation customerUpdate($customer: CustomerUpdateInput!, $customerAccessToken: String!) {
      customerUpdate(customer: $customer, customerAccessToken: $customerAccessToken) {
        customer {
          id
          email
        }
        customerAccessToken {
         accessToken
        }
        customerUserErrors {
         field
          message
        }
        userErrors {
          field
          message
        }
      }
    }`,
      variables: {
        customer: { password: customerData.passwd },
        customerAccessToken: token,
      },
    });
    return await graphqlRequest(data);
  }

  async getCustomer() {
    let data = JSON.stringify({
      query: `query customerQuery($customerAccessToken: String!) {
        customer(customerAccessToken: $customerAccessToken) {
          id
          firstName
          lastName
          email
          phone
           metafields(identifiers: [{ namespace: "custom", key: "gameId" }]) {
            value
            id
            key
            namespace
            description
          }
        }
      }`,
      variables: {
        customerAccessToken: token,
      },
    });
    return await graphqlRequest(data);
  }
  async addMetafields(customerId, gameId) {
    try {
      return await axios(
        `${process.env.REACT_APP_MONOFF_SERVER_URL}/shopify/gameId`,
        {
          method: "post",

          data: {
            customerId,
            gameId,
          },
        }
      ).then((res) => res.data);
    } catch (e) {
      throw new Error(e);
    }
  }
  async login(customerData) {
    let data = JSON.stringify({
      query: `mutation CustomerAccessTokenCreate($input: CustomerAccessTokenCreateInput!) {
            customerAccessTokenCreate(input: $input) {
              customerAccessToken {
                accessToken
                expiresAt
              }
              customerUserErrors {
                code
                field
                message
              }
            }
          }`,
      variables: {
        input: { email: customerData.email, password: customerData.passwd },
      },
    });
    return await graphqlRequest(data);
  }
  async checkGameId(gameId) {
    let res = await axios(
      `${process.env.REACT_APP_MONOFF_SERVER_URL}/dynamo/checkuser`,
      {
        method: "post",
        data: { userId: gameId },
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return res.data;
  }
  async customerPayment(orderId) {
    let res = await axios(
      `${process.env.REACT_APP_MONOFF_SERVER_URL}/shopify/order/detail/payment`,
      {
        method: "post",
        data: { orderId },
      }
    );
    return res.data;
  }
  async resetPasswd(email) {
    let data = JSON.stringify({
      query: `mutation CustomerRecover($email: String!) {
      customerRecover(email: $email) {
        customerUserErrors {
          field
          message
          code
        }
      }
    }`,
      variables: { email },
    });
    return await graphqlRequest(data);
  }
  async sendContactMail(data) {
    return await axios(
      `${process.env.REACT_APP_MONOFF_SERVER_URL}/email/shop/contact`,
      {
        method: "post",
        data,
      }
    )
      .then((res) => res)
      .catch((e) => e);
  }
}
