import React from "react";
import styles from "./ProductDetailInfo.module.scss";
import { useEffect, useState } from "react";
import { useProduct } from "../context/ProductContext";

export default function ProductDetailInfo() {
  const { detailInfo } = useProduct();
  const [price, setPrice] = useState("");

  useEffect(() => {
    setPrice(Number(detailInfo?.variants?.edges[0].node.price.amount));
  }, [detailInfo?.variants?.edges]);

  return (
    <section className={styles.container}>
      <p className={styles.chip} data-content={detailInfo?.productType}>
        {detailInfo?.productType?.toUpperCase()}
      </p>
      <h2>{detailInfo?.title}</h2>
      <span className={styles.product_price}>
        <p className={styles.price}>
          {price?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </p>
        <p className={styles.currency}>(USD)</p>
      </span>
    </section>
  );
}
