import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styles from "./Login.module.scss";
import { ReactComponent as EyeIcon } from "../img/eye-open.svg";
import { ReactComponent as EyeCloseIcon } from "../img/eye-close.svg";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useCustomer } from "../context/CustomerContext";
import { getCookie, setCookie } from "../cookie/cookie";
import { clickEvent, linkClickEvent, pageView } from "../api/ga4";
export default function Login() {
  const { login } = useCustomer();
  const navigate = useNavigate();
  const location = useLocation();
  const token = getCookie("access-token");
  const [isPasswordViewClick, setIsPasswordViewClick] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [loginData, setLoginData] = useState({
    email: "",
    passwd: "",
  });
  const passwdRef = useRef(null);
  const onPasswordViewClick = () => {
    setIsPasswordViewClick(!isPasswordViewClick);
  };
  useEffect(() => {
    pageView("Login", location.pathname);
  }, [location]);
  useEffect(() => {
    token && navigate("/");
  }, [navigate, token]);
  useEffect(() => {
    const passwdLabel = document.getElementsByClassName(styles.passwd_label);
    passwdRef.current.onfocus = function (e) {
      passwdLabel[0].style.border = "1px solid var(--gray-95, #2e2e2e)";
    };
    passwdRef.current.onblur = function (e) {
      passwdLabel[0].style.border = "1px solid var(--gray-10)";
    };
  }, []);
  const onLoginDataChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "email":
        setLoginData((prev) => ({ ...prev, email: value }));
        return;
      case "passwd":
        setLoginData((prev) => ({ ...prev, passwd: value }));
        return;
      default:
        setLoginData({});
        return;
    }
  };
  const onLoginClick = () => {
    clickEvent("login", "login", location.pathname);
    setIsLogin(true);
    login(loginData).then(async (res) => {
      if (!res?.customerAccessTokenCreate?.customerAccessToken) {
        setIsLogin(false);
        return;
      } else {
        await setCookie(
          "access-token",
          res?.customerAccessTokenCreate?.customerAccessToken?.accessToken,
          {
            httpOnly: false,
            path: "/",
            secure: true,
            sameSite: "None",
            expires: new Date(
              res?.customerAccessTokenCreate?.customerAccessToken?.expiresAt
            ),
          }
        );
        window.location.href = "/";
      }
    });
  };
  const onEnterPress = (e) => {
    if (e.key === "Enter") {
      clickEvent("login", "login", location.pathname);
      onLoginClick();
    }
  };
  const onLinkClick = (title, action, pathName) => {
    linkClickEvent(title, action, pathName);
  };
  return (
    <div className={styles.container}>
      <h3 className={styles.title}>Login</h3>
      <div className={styles.inner}>
        <div className={styles.login_email}>
          <p>Email</p>
          <input type="email" name="email" onChange={onLoginDataChange} />
        </div>
        <div className={styles.login_password}>
          <p>Password</p>
          <label className={styles.passwd_label}>
            <input
              name="passwd"
              onChange={onLoginDataChange}
              type={isPasswordViewClick ? "text" : "password"}
              ref={passwdRef}
              onKeyDown={onEnterPress}
            />
            {isPasswordViewClick ? (
              <EyeCloseIcon onClick={onPasswordViewClick} />
            ) : (
              <EyeIcon onClick={onPasswordViewClick} />
            )}
          </label>
        </div>
        <div
          className={styles.btnLogin}
          onClick={onLoginClick}
          readOnly={isLogin ? true : false}
        >
          Sign In
        </div>
        <ul>
          <li>
            <Link
              to="/passwd/reset"
              onClick={() =>
                onLinkClick("Login", "To find password", location.pathname)
              }
            >
              Forgot your password?
            </Link>
          </li>
          <li>
            <Link
              to="/signup"
              onClick={() =>
                onLinkClick("Login", "To Signup", location.pathname)
              }
            >
              Create account
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}
