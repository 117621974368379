import React from "react";
import { useProduct } from "../context/ProductContext";
import styles from "./ProductDetailImages.module.scss";
import useWindowSize from "../custom/useWindowSize";
export default function ProductDetailImages() {
  const { detailImages } = useProduct();
  const { width } = useWindowSize();

  return (
    <section className={styles.container}>
      {width > 1439 ? (
        detailImages?.map((img) => (
          <img
            src={img.node.src}
            alt="detail_image"
            className={styles.product_image}
          />
        ))
      ) : (
        <img
          className={styles.single_img}
          src={detailImages[0]?.node?.src}
          alt=""
        />
      )}
    </section>
  );
}
