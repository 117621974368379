import React from "react";
import styles from "./OrderItem.module.scss";
export default function OrderItem({ product }) {
  return (
    <li className={styles.container}>
      <img
        src={product?.node?.variant?.image?.url}
        alt=""
        className={styles.product_img}
      />
      <div className={styles.product_info}>
        <h3>{product?.node?.title}</h3>
        <span>
          <p>{product?.node?.variant?.title}</p>
          <p>/</p>
          <p>{product?.node?.quantity}</p>
        </span>
        <p className={styles.total_prize}>
          {Number(product?.node?.originalTotalPrice?.amount).toLocaleString(
            "en-US",
            {
              style: "currency",
              currency: "USD",
            }
          )}
        </p>
      </div>
    </li>
  );
}
